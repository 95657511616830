import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, AppBar } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Keyboard, { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
// import { useHistory } from 'react-router-dom';
import NavigationDisclaimer from '../components/exam/NavigationDisclaimer';
import SectionItem from '../components/exam/SectionItem';
import ResponseFieldAdder from '../components/exam/ResponseFieldAdder';
import { IRResponseSchema, IRResponseMap } from '../components/exam/QuestionRegex';
import { SUBJECT_IR_QUERY } from '../gql/queries';
import { SUBJECT_ITEM_RESPONSE_MUTATION } from '../gql/mutations';
import { useMyContext } from "../Context";
import IncompleteDialog from '../components/exam/IncompleteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    disclaimer: {
      marginTop: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    sectionGrid: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    inputBox: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 'auto',
      marginTop: '10px'
    },
    dialogText: {
      display: 'flex',
      alignItems: 'center',
      margin: '32px',
      padding: 'auto'
    },
    keyboardShow: {
      position: 'sticky'
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    appBarHidden: {
      display: 'none'
    },
    longTextField: {
      display: 'flex'
    },
  })
);



export default function Exam() {
  /**
   * TODO: Docstring to be added when refactoring b/c dear lord this is a mess
   */
  const classes = useStyles();
  const history = useHistory();
  // credentials
  const tokenVal = sessionStorage.getItem('token');


  // check if the NavigationDisclaimer has been shown
  const [shownDisclaimer, setShownDisclaimer]: [boolean, Function] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);

  // current responsid storage
  const responseId = sessionStorage.getItem('responseId');
  // object to hold the form responses
  const [irResponseObject, setIrResponseObject]: [
    IRResponseMap, Function
  ] = useState<IRResponseMap | {}>({});
  // special character state, propagated to/from context
  const { specialChars, setSpecialChars } = useMyContext();
  /**
   * Keyboard states: ref is necessary for the internal keyboard state,
   * inputName helps assign it to the correct text field, textInputFocused is
   * the trigger for displaying the keyboard, and then layoutNamehandles the
   * different shift cases
   */
  const keyboard = useRef(null);
  const [inputName, setInputName] = useState("default");
  const [textInputFocused, setTextInputFocused] = useState(false);
  const [layoutName, setLayoutName]: [string, Function] = useState("default");
  // graphql query and mutation
  const { data, error, loading } = useQuery(
    SUBJECT_IR_QUERY,
    {
      variables:
      {
        "subjectToken": tokenVal,
        "responseId": responseId,
      }
    }
  );
  const [
    itemResponseMutation,
    itemResponseMutationResult
  ] = useMutation(SUBJECT_ITEM_RESPONSE_MUTATION);
  /*
   * newSection starts false, set to true on form submission, and is a flag to
   * indicate where to look to define section when populating the form state,
   * as handled by the code block after this
   */
  const [newSection, setNewSection] = useState(false);

  /*
   * Each exam section will have a new section object that the response form
   * fields need to be populated into state from. In the first part of the exam
   * the section object will arrive as the ir field on the graphql _query_, but
   * all further exam sections (and thus every further render) will be from the
   * section field of the graphql _mutation_ resulting from submitting the
   * previous section.
   *
   * Thus, the form's onSubmit sets the newSection flag to true, at which point
   * the conditional logic below tells it whether to check the response to the
   * mutation or query to define the section object. Because of the nature of
   * this operation, it's possible for the query/mutation response object to
   * (briefly) not have the correct fields, hence the ?. operators at each
   * step of the assignment to section.
   *
   * Then, the useEffect hook maps section.items into a new array of question
   * objects and then sets the form state (irResponses) to that new array. The
   * useEffect hook is set to fire if section or newSection change.
   */

  let section: any;
  if (newSection) {
    section = itemResponseMutationResult?.data?.subject?.itemResponse?.section
  } else {
    section = data?.subject?.ir
  };
  useEffect(() => {
    if (section) {
      const responseFields = [].concat(...section.items.map(
        (item: any) => ResponseFieldAdder({
          question: item.question,
          signature: item.signature,
          radioOptions: item.radioOptions,
          textArea: item.textArea,
        })
      ));
      const tempIrResponse: IRResponseMap = Object.fromEntries(
        (responseFields as IRResponseSchema[]).map((item) => [`${item.signature}${item.entryId}`, item])
      );
      setIrResponseObject(tempIrResponse);
      document.getElementById("spacer")?.scrollIntoView()

    }
  }, [section, newSection]);

  /*
   * Set special characters in global context for special keyboard component
   * Done here as well as in classification. Note that because of the way the
   * virtual keyboard works, we convert upper and lower cases to single strings
   * so they can be displayed as a single layout each
   */
  useEffect(() => {
    if (data?.subject?.clientSettings) {
      const { upper, lower } = data.subject.clientSettings.specialChars
      setSpecialChars([lower.join(' ') as string, upper.join(' ') as string])
    }
  }, [data?.subject?.clientSettings?.specialChars]);

  // Conditionally return loading/error screens as necessary
  if (loading || itemResponseMutationResult.loading) {
    return <>
      <Typography>Finishing loading exam...</Typography><CircularProgress />
    </>
  };
  if (error) {
    return <Typography>We are sorry, there has been an error.</Typography>
  };

  // From here on, things that care about the backend query/mutation can happen

  // Load subject name for display during the exam
  const { firstName, lastName } = data?.subject?.subject;

  // If exam is complete (signalled by receiving an irResponse with a section: null) move to Finished
  if (section === null) {
    history.push('/finished')
    return <>
      <Typography>Exam completed. Processing...</Typography> <CircularProgress />
    </>

  };

  // change handler function for input elements
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    /** spread operator so the end result is a new object identical except for
      * the response update 
      */
    setIrResponseObject(
      {
        ...irResponseObject,
        [inputName]: { ...irResponseObject[inputName], response: event.target.value }
      }
    );
    // update keyboard ref, the if statement is to satisfy typescript
    if (keyboard.current) { (keyboard.current as any).setInput(event.target.value) };
  };


  /** change handler function for keyboard, which handles different events
    * and needs a preventDefault to keep it from taking focus from the text
    * field it's working with at the moment
    */
  const onChangeAll = (inputs: KeyboardInput, event: any) => {
    // preventDefault keeps it from taking focus
    event.preventDefault();
    /** spread operator so the end result is a new object identical except for
      * the response update 
      */
    setIrResponseObject(
      {
        ...irResponseObject,
        [inputName]: { ...irResponseObject[inputName], response: inputs[inputName] }
      }
    );
  };

  // Actually populate the questions
  const items = section.items.map((item: any) =>
    <li key={item.id} style={{ listStyleType: "none" }}>
      {SectionItem({
        classes,
        setTextInputFocused,
        setInputName,
        handleChange,
        setLayoutName,
        signature: item.signature,
        irResponseObject,
        question: item.question,
        instructions: item.instructions,
        media: item.media,
        selectOptions: item.selectOptions,
        radioOptions: item.radioOptions,
        textArea: item.textArea,
      })}
    </li>
  );

  // submission handler function
  const handleSubmit = () => {
    itemResponseMutation({
      variables: {
        "irResponses": Object.values(irResponseObject),
        "token": tokenVal,
      }
    });
    setNewSection(true);
  };

  // the form proper
  const examForm = <>
    <form
      id='form_for_exam'
      name="form_for_exam"
      autoComplete="off"
      onInvalid={(e) => { e.preventDefault(); setDialogOpen(true) }}
      onSubmit={handleSubmit}
    >
      <ul>
        {items}
      </ul>
      <Button
        type="submit"
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      <IncompleteDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        classes={classes}
        handleSubmit={handleSubmit}
      />

    </form>
  </>;


  return (
    <>
      <Container maxWidth="lg">
        <TextField variant="filled" disabled label="Student Name" defaultValue={`${firstName} ${lastName}`} />
        <Typography variant="h4">{section.title}</Typography>
        {/* eslint-disable-next-line react/no-danger */}
        <Typography variant="h5" dangerouslySetInnerHTML={{ __html: section.des }} />
        <Typography variant="h5">{section.instruction}</Typography>
        <Divider />
        {/**
          * Next line checks if they were shown the nav disclaimer, if true
          * renders exam, if not renders the disclaimer.
          * */}
        {
          shownDisclaimer ? examForm : NavigationDisclaimer(
            { classes, setShownDisclaimer }
          )
        }
        {/** AppBar here is used to handle positioning, the Grow element
           * handles the transform to display the keyboard when a text field
           * is focused on
           * */}
        <AppBar position="sticky" color='transparent' className={(textInputFocused) ? classes.appBar : classes.appBarHidden}>
          <Grow in={textInputFocused}>
            <Paper className={classes.keyboardShow} onMouseDown={(e) => e.preventDefault()}>
              <Keyboard
                // react hates the ref being an assignment
                // eslint-disable-next-line
                keyboardRef={r => (keyboard.current = r)}
                // used for tracking destination on the irResponseObject
                inputName={inputName}
                // change handler function
                onChangeAll={onChangeAll}
                // these assign upper/lower as layouts
                layoutName={layoutName}
                layout={{
                  default: [specialChars[0].concat(" {shift}")],
                  shift: [specialChars[1]?.concat(" {shift}")]
                }}
                // have the shift key trigger switching layouts
                onKeyPress={button => { if (button === "{shift}") setLayoutName(layoutName === "default" ? "shift" : "default") }}
              />
            </Paper>
          </Grow>
        </AppBar>
      </Container>
    </>
  );
};

