/* Basic Sign in component for our student subject.
 * Takes three fields: client, examLanguage, subject, and sends a mutation to create a subject. 
 * This mutation returns a token, which is saved into local storage. The loggedIn status is 
 * changed via Context to allow for changes in the dashboard. History is used for navigation
 * depending upon the other fields ( hasDemographics etc), that are returned with the mutation. 
*/

import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import LockIcon from '@material-ui/icons/LockOutlined';

import { useMyContext } from "../../Context";
import LanguageSelect from './LanguageSelect';

import { SUBJECT_LOGIN_MUTATION } from '../../gql/mutations';
import { LOGIN_CLIENT_LANGUAGE_QUERY } from '../../gql/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
        .spacing(3)}px`
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    language: {
      textTransform: 'capitalize'
    },
    loading: {
      margin: 'auto',
      display: 'block'
    }
  })
);


export default function SignInSubject() {
  const history = useHistory();
  const classes = useStyles();
  // TODO: before moving to prod, replace this bit with the commented-out line:
  // const client = window.location.hostname.split(".")[0];  
  let [client,] = window.location.hostname.split(".");
  if (client === 'localhost') {
    client = 'lehman'
  };
  // hooks for tracking state 
  const [examLanguage, setExamLanguage] = useState('');
  const [subjectId, setSid] = useState('');
  const { setLoggedStatus, setUser, setAdminStatus } = useMyContext();
  // graphql mutation and queries
  const [
    submitSubjectLoginMutation,
    { loading, error, data }
  ] = useMutation(SUBJECT_LOGIN_MUTATION);
  const clientSettings = useQuery(LOGIN_CLIENT_LANGUAGE_QUERY,
    {
      variables: { "client": client }
    });
  // set disabled/error message vars
  let disabled = false;
  let msg;

  /** if clientSettings query returns an error we should return something to
   * show that 
   * */ 
  if (clientSettings.error) {
    return <Typography color="textSecondary"> We are sorry, there has been an error. </Typography>
  }
  /**
   * logic to handle the login mutation returning- disable while loading,
   * show an error if errored, when it finishes push to the next section
   */
  if (loading) {
    disabled = true;
    msg = <p>Logging In...</p>;
  } else if (error) {
    msg = <p>{error.message}</p>;
  } else if (data) {
    sessionStorage.setItem('token', data.subjectLogin.token);
    sessionStorage.setItem('responseId', data.subjectLogin.responseId);
    localStorage.removeItem('instructorToken');
    setLoggedStatus(true);
    setAdminStatus(false);
    setUser(JSON.parse(data.subjectLogin.token.split(".")[0]).sid);
    // eslint-disable-next-line no-console
    console.log(data.subjectLogin.token)
    if (!data.subjectLogin.hasDemographics) {
      history.push('/demographics')
    } else if (!data.subjectLogin.hasLanguageCategorization) {
      history.push('/classification')
    } else if (data.subjectLogin.hasCompletedExam) {
      history.push('/finished')
    } else {
      history.push('/exam')
    }
  };
  // history.replace('/demographics'); <- leaving this in
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Student Login
        </Typography>

        <form
          className={classes.form}
          onSubmit={event => {
            event.preventDefault();
            submitSubjectLoginMutation({
              variables: {
                client,
                language: examLanguage,
                subjectId,
              }
            });

          }}
        >
          {msg}
          <LanguageSelect
            examLanguage={examLanguage}
            setExamLanguage={setExamLanguage}
            clientSettings={clientSettings}
            classes={classes}
          />
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="subjectId">subjectId</InputLabel>
            <Input
              disabled={disabled}
              name="subjectId"
              value={subjectId}
              id="subjectId"
              onChange={(e) => setSid(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={client === '' || subjectId === '' || examLanguage === ''}
          >
            Sign in
          </Button>
        </form>
      </Paper>
    </main>
  );
};
