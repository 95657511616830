import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import LockIcon from '@material-ui/icons/LockOutlined';
import { useMyContext } from "../../Context";
import { INSTRUCTOR_LOGIN_MUTATION } from '../../gql/mutations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
        .spacing(3)}px`
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    }
  })
);

export default function SignInInstructor() {
  const history = useHistory();
  const classes = useStyles();
  // TODO: before moving to prod, replace this with the next line
  // const client = window.location.hostname.split(".")[0];  
  let [client,] = window.location.hostname.split(".");
  if (client === 'localhost') {
    client = 'lehman'
  };
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setAdminStatus, setUser, setLoggedStatus } = useMyContext();
  const [
    submitInstructorLoginMutation,
    { loading, error, data }
  ] = useMutation(INSTRUCTOR_LOGIN_MUTATION);
  // not state b/c we don't want to trigger rerenders when they change
  let status = '';

  // logic to handle waiting for results/unsuccessful login/successful login
  if (loading) {
    status ="Logging In...";
    } else if (error) {
    status ="We're sorry, there has been an error.";
  } else if (data) {
    if (data.instructorLogin?.token === null) {
      status = 'Username or password is incorrect.';
    }
    else if (data.instructorLogin?.token) {
      localStorage.setItem('instructorToken', data.instructorLogin.token);
      history.push('/instructor_dash');
      setAdminStatus(true);
      setLoggedStatus(true);
      setUser(JSON.parse(`${data.instructorLogin.token!.split("}.")[0]}}`).iid)
    }
  };

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Instructor Login
        </Typography>
        <form
          className={classes.form}
          onSubmit={event => {
            event.preventDefault();
            submitInstructorLoginMutation({
              variables: {
                client,
                username,
                password
              }
            });

          }}
        >
          <Typography>{status}</Typography>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input
              name="username"
              value={username}
              type="username"
              id="username"
              autoComplete="current-username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              value={password}
              id="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign in
          </Button>
        </form>
      </Paper>
    </main>
  );
};
