import React, { ReactElement } from "react";
import QuestionRegex from "components/exam/QuestionRegex";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ResponseItemSchema } from "gql/schemas";

import { QuestionSchema } from "./ResponseQuestionsArrayConstructor";
import QuestionTextBox from "./QuestionTextBox";


type ResponseQuestionParserProps = {
  classes: ClassNameMap,
  question: QuestionSchema
};
export default function ResponseQuestionParser(
  {
    classes,
    question: { text, answers }
  }: ResponseQuestionParserProps): Array<ReactElement> {
  /**
   * Given a QuestionSchema object, parses it and returns an array of
   * React elements corresponding to the text of the question, placing
   * any in-line answers inside the text and any remaining answers at the
   * end.
   * 
   * @remarks
   * Calls QuestionRegEx to parse the question's text field into an array,
   * then maps each part of that array to either a dangerouslySetInnerHTML
   * div for simple strings, or calls a creates a QuestionTextBox element
   * (which is just a wrapper for a jsx combination of TextField and
   * FormHelperText) for strings that match the beginning with
   * a '{{' and ending with '}}' pattern that marks where inputs go. 
   * 
   * It tracks which answer to put in which {{box}} via an index variable,
   * and if not all answers are used (so if the index is less than
   * question.answers.length), it appends QuestionTextBox elements
   * corresponding to the remaining answers.
   * 
   * @param classes - classNameMap passed down for css purposes
   * @param question - the QuestionSchema being parsed, destructured here
   * into text (a string) and answers (an array of ResponseItemSchema 
   * objects)
   * 
   * @returns An array of React elements
   * 
   */
  // Use QuestionRegex to split the question field into an array
  const questionParts = QuestionRegex(text!);
  let index = 0;
  // Loop over the array
  const elementArray = questionParts.map(
    (part: string) => {
      if (part.slice(0, 2) === "{{" && part.slice(-2) === "}}") {
        const box =
          <QuestionTextBox classes={classes} answer={answers[index]} />;
        index += 1;
        return box;
      };
      return <div
        className={classes.questionElement}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: part }}
      />;
    }
  );
  if (index < answers.length) {
    answers.slice(index).forEach(
      (answer: ResponseItemSchema) => {
        elementArray.push(
          <QuestionTextBox classes={classes} answer={answer} />
        )
      }
    )
  };
  return elementArray;
};
