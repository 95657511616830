/*
 * All graphQL mutations
 */
import gql from 'graphql-tag';

export const INSTRUCTOR_LOGIN_MUTATION = gql`
	mutation instructorLogin($client: String!, $password: String!, $username: String!) {
  		instructorLogin(client: $client, password: $password, username: $username) {
    		token
  		}
	}
`;

export const SUBJECT_LOGIN_MUTATION = gql`
	mutation subjectLogin($client: String!, $language: String!, $subjectId: String!) {
  		subjectLogin(client: $client, language: $language, subjectId: $subjectId) {
    		token
        hasDemographics
        hasLanguageCategorization
        hasInProgresssExam
        hasCompletedExam
        responseId
  		}
	}
`;

export const SUBJECT_DEMOGRAPHICS_FORM_SUBMIT_MUTATION = gql`
mutation AuthMutations(
  $age: Int,
  $email: String,
  $firstName: String!,
  $gender: String,
  $lastname: String!,
  $otherLanguagesSpoken: [String],
  $race: String,
  $token: String
  ) {
    subject(token: $token)
        {
      demographics(
        age: $age,
        email: $email,
        firstName: $firstName,
        gender: $gender,
        lastName: $lastname,
        otherLanguagesSpoken: $otherLanguagesSpoken,
        race: $race,
      ) {
        ok
        }
    }
}
`;

export const SUBJECT_CREATE_RESPONSE_MUTATION = gql`
mutation AuthMutations($token: String) {
  subject(token: $token){
  createResponse {
    responseId
    subjectToken
  }
}
}`;

export const SUBJECT_CLASSIFICATION_MUTATION = gql`
mutation AuthMutations(
  $heritageResponses: [SubjectHeritageResponseInputSchema]!,
  $previousLevel: String,
  $reason: String,
  $token: String,
  $writingSample: String!
  ) {
    subject(token: $token)
     {
      classification(
        heritageResponses: $heritageResponses,
        previousLevel: $previousLevel,
        reason: $reason,
        writingSample: $writingSample
        ){
        ok
      }
  }
}`;

export const SUBJECT_ITEM_RESPONSE_MUTATION = gql`
mutation AuthMutations(
  $irResponses: [IRResponseSchema]!,
  $token: String
  ) {
    subject(token: $token)
    {
    itemResponse(
      irResponses: $irResponses,
      ){
        section {
          id
          title
          des
          instruction
          inert
          examId
          items{
            id
            signature
            bankId
            examId
            vars
            ans{
              entry
              pnt
              val
            }
            stats
            media {
              name
              caption
              type
              file
            }
            question
            textArea
            instructions
            selectOptions{
              entry
              values
            }
            radioOptions
            inert
          }
        }
      }
  }
  }
`;
// an empty new exam's token 
// "token": "{\"sid\": \"testWork\", \"l\": \"spanish\", \"d\": 7200, \"c\": \"lehman\"}.YOXNOw.2cxX7vvi-86nJ0hF7h_oO7KHMfE"