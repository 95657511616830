import React from "react";
import Hero from "components/layouts/Hero";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";



/*
 * If not logged in, display message indicating the need to log in and
 * a button navigating to instructor login page
*/

export default function NotLoggedInAsInstructor() {
  return <>
    <Hero>
      <Typography variant='h4'>
        You must be logged in to view exams.
      </Typography>
      <Button
        variant="contained"
        href="/instructor"
      >
        Return to instructor login page
      </Button>
    </Hero>
  </>
};
