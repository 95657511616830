/*
 * Checks if the given MediaSchema has a value, and if not returns an empty jsx.
 * If it does have a value, returns a Button which plays media when clicked. 
 * Currently only plays audio, can be extended by type fairly easily with a
 * switch case.
 */

import React from 'react';

import Button from '@material-ui/core/Button'
import PlayArrow from '@material-ui/icons/PlayArrow';
import { rootUrl } from '../../Constants'

/*
 * Note that the schema is a list of objects. This mirrors how it appears in
 * the API.
 */
export type MediaSchema = [{
  name: string,
  caption: string,
  type: string,
  file: string
}]

export default function MediaLoader(media: MediaSchema) {
  /*
   * TODO: If a given ItemDAO has multiple media in the list, there should
   * be a way of pointing at the correct one
   */
  const [{ name, caption, file }] = media;
  // TODO: Change baseURL so that the URL is a variable, sourced from elsewhere
  const baseURL = `${rootUrl}/static/exam/`;
  const processedURL = `${baseURL}${name}/${file}`;
  // TODO: Make mediaElement video-capable using the type field on MediaSchema
  const mediaElement = document.getElementById(file) as HTMLAudioElement;
  return (
    <>
      <Button
        variant="contained"
        onClick={() => { mediaElement.play() }}
        startIcon={<PlayArrow />}
      >
        Play {name}
      </Button>
      <audio className="audio-element" id={file}>
        <source src={processedURL} />
        <track src={caption} kind="captions" />
      </audio>
    </>
  )
};