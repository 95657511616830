/*
 * Processes a single SectionDAO item into a format 
 */

import QuestionRegex, { IRResponseSchema } from './QuestionRegex'

export type ResponseFieldAdderProps = {
  question: string,
  signature: string,
  radioOptions: string[] | null,
  textArea: boolean
};

export default function ResponseFieldAdder({
  question,
  signature,
  radioOptions,
  textArea,
}: ResponseFieldAdderProps) {
  /**
   * Splits the question up using regex to create an array (questionText), then
   * for each element in the array, if it is in the style that signifies a form
   * field (currently those are formatted like: {{box1}} ), pushes an 
   * IRResponseSchema object into fieldsArray.
   * 
   * Note that this returns an array each time that it's called, so using it
   * inside a .map method will return an array of arrays unless the spread
   * syntax is used.
   * @example
   * const foo = [];
   * foo.concat(...section.items.map(
   *      (item: any) => ResponseFieldAdder({
   *        id: item.id,
   *        question: item.question
   *        signature: item.signature
   *      })
   *      ));)
   */
  const questionText: string[] = QuestionRegex(question)
  const fieldsArray: IRResponseSchema[] = []
  questionText.forEach(
    (s: string) => {
      if (s[0] === "{") {
        const name: string = s.slice(2, -2)
        fieldsArray.push(
          { "entryId": name, "signature": signature, "response": '' }
        )
      }
    });
  if (radioOptions?.length) {
    fieldsArray.push({ "entryId": '', "signature": signature, "response": '' })
  };
  if (textArea === true) {
    fieldsArray.push({ "entryId": '', "signature": signature, "response": '' })
  };
  return fieldsArray
};

