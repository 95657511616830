import React, { ReactElement } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ResponseItemSchema } from "gql/schemas";

type QuestionTextBoxProps = {
  classes: ClassNameMap;
  answer: ResponseItemSchema;
};
export default function QuestionTextBox(
  {
    classes: { textField, questionElement },
    answer: { itemId, response, pnts }
  }: QuestionTextBoxProps
): ReactElement {
  /**
   * Creates a TextField + FormHelperText jsx element from a
   * ResponseItemSchema object
   * @param classes - classNameMap passed down for css purposes
   * @param answer - ResponseItemSchema parsed into TextField+FormHelperText
   *
   * @returns
   * JSX consisting of a TextField element followed by FormHelperText element
   */
  return <>
    <TextField
      className={textField}
      id={itemId}
      value={response} />
    <FormHelperText className={questionElement}>Points: {pnts}</FormHelperText>
  </>;
}
;
