/*
 * All routes that a user is allowed to access when logged in
 * which is the majority of the functionality of the application.
 *
 * The route of protected or unprotected is determined in the
 * App.js file where the presence of a token will determine if
 * they will be served protected or unprotected routes.
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';


import Landing from '../views/Landing';
import DemographicsForm from '../views/DemographicsForm';
import Classification from '../views/Classification';
import NotFound from '../views/NotFound';
import Student from '../views/Login/Student';
import Instructor from '../views/Login/Instructor';
import Exam from '../views/Exam';
import ResponseView from '../views/ResponseView';
import InstructorMain from '../views/InstructorMain'
import Finished from '../views/Finished';

export default () => (
  /* Currently this puts everything inside a single ErrorBoundary, which may be
   * either overkill or too coarse a grain. If we don't get any errors, cut it, 
   * if we start getting a bunch, we'll need to make it more fine-grained.
   * TODO: Better fallback.
   */
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route path="/student" exact component={Student} />
    <Route path="/instructor" component={Instructor} />
    <Route path="/demographics" component={DemographicsForm} />
    <Route path="/classification" component={Classification} />
    <Route path="/instructor_dash" component={InstructorMain} />
    <Route path="/response_view/:subjectId" component={ResponseView} />
    <Route path="/exam" component={Exam} />
    <Route path="/finished" component={Finished} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
