/*
 * This is our not found page for users that are not logged in, so
 * they are not expecting to see a navbar only our header.
 */
import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import Body from '../components/layouts/Body'

const styles = (theme: Theme) => createStyles({
  layoutBody: {
    marginTop: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  }
});

interface Props extends WithStyles<typeof styles> {
};

function NotFound(props: Props) {
  const { classes } = props;
  return (
    <>
      <Body className={classes.layoutBody}>
        <Typography variant="h4">Error! Page not found!</Typography>
      </Body>
    </>
  );
};

export default withStyles(styles)(NotFound);