/*
 * This is our landing page which is composed of a list of
 * static components for marketting display. These components do not yet exist, but 
 * they will be found in components/landing. 
 */
import React from 'react';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

import Hero from '../components/layouts/Hero' // We need to desperately fix this



export default function Landing() {
  return (
    <> { /* This is the shorthand, that is preferred for a <Fragment></Fragment> structure. If you need a key, use <Fragment> */}
      <Hero>
        <Typography variant="h2" align='center'>
          Welcome to the Spanish Language Heritage Learner Online Placement Exam
        </Typography>
        <Button
          variant="contained"
          href="/student"
          color="primary"
        >
          Take Exam
        </Button>
      </Hero>
      <Box>
        <Button
          variant="outlined"
          href="/instructor"
          color="primary"
          fullWidth={false}
        >
          Instructor Login
        </Button>
      </Box>
    </>
  );
};
