import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Keyboard from "react-simple-keyboard";

import NavButton from './NavButton';

// Type declaration here for legibility
type WritingPromptCardProps = {
  classes: any,
  writingPrompt: string,
  writingSampleInput: string,
  setWritingSampleInput: Function,
  reasonInput: string,
  reasonOptions: string[],
  setReasonInput: Function,
  previousLevelOptions: string[],
  previousLevelInput: string,
  setPreviousLevelInput: Function,
  buttonReady: boolean,
  setButtonReady: Function,
  setProgress: Function,
  position: number,
  setPosition: Function,
  keyboard: React.MutableRefObject<any>,
  specialChars: string[],
  layoutName: string,
  setLayoutName: Function
}

export default function WritingPromptCard(
  {
    classes,
    writingPrompt,
    writingSampleInput,
    setWritingSampleInput,
    reasonOptions,
    reasonInput,
    setReasonInput,
    previousLevelOptions,
    previousLevelInput,
    setPreviousLevelInput,
    buttonReady,
    setButtonReady,
    setProgress,
    position,
    setPosition,
    keyboard,
    specialChars,
    layoutName,
    setLayoutName,
  }: WritingPromptCardProps
): JSX.Element {
  /**
   * Renders the writing prompt card.
   * 
   * @remarks 
   * The writing prompt card is the second card served as part of the 
   * classification view, and the first one that actually collects subject input
   * The component displays the writing prompt, has a text field for the 
   * writingSample and a virtual keyboard to enter in special characters. 
   * Next it has two Select fields for the "Reason for taking Spanish" 
   * (reason) and "Previous Level of Spanish" (previousLevel) field info. It also
   * has two NavButtons: a Back to navigate back to the disclaimer, and a Submit
   * that navigates forward (but note that it is NOT what adds the data to state,
   * the text field and select components do that themselves).
   * 
   * @param classes - classNameMap for passing down CSS
   * @param writingPrompt - string, the actual writing prompt given to the
   * subject
   * @param writingSampleInput - string, the state tracking the value of the
   * writing sample text field
   * @param setWritingSampleInput - dispatch function to update the state of
   * writingSampleInput
   * @param reasonOptions - array of strings to populate the select options for the
   * possible reasons the subject is taking the course
   * @param reasonInput - string tracking state of the currently-selected
   * reason for taking the course
   * @param setReasonInput - dispatch function to update state of reasonInput
   * @param previousLevel - array of strings to populate the select options for
   * the question asking what the subject's previous highest level of education
   * in the language was
   * @param previousLevelInput - string tracking state of the currently-
   * selected answer to the subject's previous highest level of education in
   * the language
   * @param setPreviousLevelInput - dispatch function to update the state of
   * previousLevelInput
   * @param buttonReady - boolean tracking whether the NavButtons are disabled
   * or not
   * @param setButtonReady - dispatch function to update the state of
   * buttonReady
   * @param setProgress - dispatch function to update the progress timer num
   * @param position - number representing position in array of classification
   * section cards
   * @param setPosition - dispatch function to update state of position
   * @param keyboard - mutable ref object to track target of virtual keybord
   * @param specialChars - array of strings representing character sets for the
   * virtual keyboard. Usually two strings representing upper and lower cases
   * of the special character sets.
   * @param layoutName - string for tracking state of the currently-active
   * layout of the virtual keyboard, ie which characters it should be showing
   * @param setLayoutName - dispatch function for updating the state of
   * layoutName
   * 
   * @returns JSX elements, a Card with CardContent containing instructions, 
   * and CardActions with the writing prompt, input for it, virtual keyboard,
   * and then select inputs for the "reason for taking course" / "highest level
   * of education in the language" classification questions.
   */
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">
          Please read the following instructions.
        </Typography>
        <Divider className={classes.divider} />
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: writingPrompt }} />
      </CardContent>
      <CardActions>
        <form
          onSubmit={() => {
            setButtonReady(false)
            setProgress(0)
            setPosition(position + 1)
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <Typography>{specialChars[0]}</Typography>
              <TextField
                id="writing-prompt-input-required"
                required
                label="Write your response here"
                variant="outlined"
                margin="dense"
                multiline
                rows={8}
                fullWidth
                value={writingSampleInput}
                rowsMax={Infinity}
                onChange={(e) => {
                  setWritingSampleInput(e.target.value); if (keyboard.current) { (keyboard.current as any).setInput(e.target.value) }
                }}
                onKeyDown={(e) => (e.key === "Shift") ? setLayoutName("shift") : null}
                onKeyUp={(e) => (e.key === "Shift") ? setLayoutName("default") : null}
              />
            </Grid>
            {/** Virtual Keyboard to enter special characters in */}
            <Grid item xs={12}>
              <Keyboard
                className={classes.keyboard}
                // if it's a text element, display keyboard, otherwise hide
                // eslint-disable-next-line
                keyboardRef={r => (keyboard.current = r)}
                onChange={
                  (input: any, e: any) => { e.preventDefault(); setWritingSampleInput(input) }
                }
                layoutName={layoutName}
                onMouseDown={(e: any) => e.preventDefault()}
                layout={{
                  default: [specialChars[0].concat(" {shift}")],
                  shift: [specialChars[1]?.concat(" {shift}")]
                }}
                onKeyPress={button => { if (button === "{shift}") setLayoutName(layoutName === "default" ? "shift" : "default") }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="reason-level-input-required"
                required
                label="Please Select Your Reason for taking Spanish"
                select
                value={reasonInput}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setReasonInput(e.target.value)}
              >
                {reasonOptions.map(
                  (reasonOption: string) =>
                    <MenuItem value={reasonOption}>{reasonOption}</MenuItem>
                )
                }
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="previous-level-input-required"
                required
                label="Please Select Your Previous Level of Spanish Classes"
                select
                value={previousLevelInput}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setPreviousLevelInput(e.target.value)}
              >
                {previousLevelOptions.map(
                  (previousLevelOption: string) =>
                    <MenuItem value={previousLevelOption}>
                      {previousLevelOption}
                    </MenuItem>
                )
                }
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <NavButton
                ready={buttonReady}
                text="Back"
                color="secondary"
                effect={() => {
                  setButtonReady(false)
                  setProgress(0)
                  setPosition(position - 1)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <NavButton
                ready={buttonReady}
                text="Submit"
                type="submit"
                color="primary"
              />
            </Grid>
          </Grid>
        </form>
      </CardActions>
    </Card>
  )
};