import React, { ReactElement } from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { QuestionSchema } from "./ResponseQuestionsArrayConstructor";
import ResponseQuestionParser from "./ResponseQuestionParser";

export default function ResponseViewItemParser(
  classes: ClassNameMap,
  sectionId: string,
  questions: QuestionSchema[],
): Array<ReactElement> {
  /**
   * Given a response section object and an array of question schema object,
   * returns an array of list elements corresponding to exam questions . 
   * 
   * @remarks
   * Maps the questions array, checking if the question's sectionId matches
   * the sectionId argument, then if it does, creating a list item element
   * with contents created by ResponseQuestionParser.
   * 
   * @param classes - ClassNameMap for accessing CSS styling
   * @param sectionId - The ResponseSectionSchema that we wish to use to
   * filter the QuestionSchema objects.
   * @param questions - An array of QuestionSchema objects to be mapped into
   * list items.
   * 
   * @returns An array of React elements that correspond to the response
   * items in the current section.
   */

  return questions.map(
    (question: QuestionSchema) => {
      if (question.sectionId === sectionId) {
        return <>
          <li>
            <div className={classes.sideBySide}>
              {ResponseQuestionParser({ classes, question })}
            </div>
          </li>
        </>
      };
      return <></>;
    }
  );

};