/*
 * This function contains the regex required to process the SectionDAO Item's 
 * question string in both the ResponseAdder and SectionItem functions.
 * It is separate because the same logic is used in both, and also because regex
 * demands a lengthy explanation.
 */

export type IRResponseSchema = {
  signature: string
  entryId: string,
  response: string,
};

export interface IRResponseMap {
  [qid: string]: IRResponseSchema
};

export default function QuestionRegex(
  question: string): string[] {
  /*  This is about to split the question up by regex. Since it's regex, gonna take
   * a second and break down exactly what /(\{\{\S+\d+\}\})/g means, going character
   * by character because otherwise it's fairly illegible.
   * 
   * /    The first slash indicates to the function that it's a regex pattern. 
   * 
   * (    In js' string.split(), enclosing the search pattern in parentheses
   *      will mean matches are used as delimiter BUT NOT REMOVED FROM THE RESULTING
   *      ARRAY.
   *
   * Then the search pattern itself:
   * 
   * \{   indicates {
   * \{   indicates another {
   * \S+  indicates any non-space via "\S" in a quantity of one or more via "+"
   * \d+  indicates any digit via "\d" in a quantity of one or more via "+"
   * \}   indicates }
   * \}   indicates another }
   *
   * This searches for {{ then one or more non-space characters, then
   * one or more digits, then }}.
   *
   * Finally, closing things out: 
   * )   for the reasons mentioned above in the open parentheses
   * \g  indicates a global match, so this will not stop at the first match.
   *
   * This is probably unnecessarily explicit, but well, regex. 
   * Thank you for your patience.
   */
  return question.split(/(\{\{\s*\S+\d+\s*\}\})/g);
};

