import React, { ReactElement } from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";


type IncompleteDialogProps = {
    dialogOpen: boolean,
    setDialogOpen: Function,
    classes: ClassNameMap,
    handleSubmit: Function,
}

export default function IncompleteDialog(
    {dialogOpen,
    setDialogOpen,
    classes,
    handleSubmit}: IncompleteDialogProps
): ReactElement {
    /**
     * Dialog element to check if the student wants to submit with incomplete
     * answers remaining.
     * 
     * @remarks IncompleteDialog is a reminder for the student that they have
     * incomplete answers- a modal dialog that makes it impossible to ignore.
     * It has a fairly simple message and a button that submits the form
     * without validation.
     * 
     * @param dialogOpen - state boolean representing whether the element is
     * shown
     * @param setDialogOpen - function setting the state of dialogOpen
     * @param classes - the classNameMap that covers css styling
     * @param handleSubmit - handler function passed down from exam to submit
     * the exam form data in a mutation. Since this dialog isn't part of the
     * form, it will ignore the required elements.
     * 
     * @returns a ReactElement consisting of a Dialog with title, text, and
     * buttons to submit or close the dialog.
     * 
     */
return <>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle className={classes.dialogTitle}>You have incomplete questions</DialogTitle>
        <DialogContentText className={classes.dialogText}>
            Do you wish to submit anyway?
            Click &quot;Submit Anyway&quot; to submit without completing the
            questions.
        </DialogContentText>
        <DialogActions>
            <Button variant='contained' color='primary' onClick={
                () => {
                    handleSubmit();
                    setDialogOpen(false)
                }
            }
            >
                Submit Anyway
            </Button>
            <Button variant='contained' onClick={() => setDialogOpen(false)} autoFocus> No </Button>
        </DialogActions>
    </Dialog>
</>}