/*
 * The submission card is the final card served as part of the classification view.
 *
 * The component renders a table of the subject's responses from previous questions
 * so that they can review them before submission, then a Back NavButton (which also
 * pops off the last heritage response since it will navigate to the last heritage
 * question card) and a Submit Navbutton which will submit the Classification
 * mutation with the information gathered from those questions.
 */
import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { HeritageResponse } from './HeritageQuestionCard';
import NavButton from './NavButton';



type SubmitCardProps = {
  classes: any,
  writingSampleInput: string,
  setWritingSampleInput: Function,
  reasonOptions: string[],
  reasonInput: string,
  setReasonInput: Function,
  previousLevelOptions: string[],
  previousLevelInput: string,
  setPreviousLevelInput: Function,
  heritageResponses: HeritageResponse[],
  setHeritageResponses: Function,
  buttonReady: boolean,
  setButtonReady: Function,
  setProgress: Function,
  position: number,
  setPosition: Function,
  subjectClassificationMutation: Function,
  tokenVal: string | null,
}

export default function SubmitCard({
  classes,
  writingSampleInput,
  setWritingSampleInput,
  reasonOptions,
  reasonInput,
  setReasonInput,
  previousLevelOptions,
  previousLevelInput,
  setPreviousLevelInput,
  heritageResponses,
  setHeritageResponses,
  buttonReady,
  setButtonReady,
  setProgress,
  position,
  setPosition,
  subjectClassificationMutation,
  tokenVal,
}: SubmitCardProps) {
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">
          Please confirm your answers were correct.
        </Typography>
        <Typography> Edit Responses? </Typography>
        <Typography> Writing Prompt Response: </Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          id="writing-prompt-input-required"
          value={writingSampleInput}
          defaultValue={writingSampleInput}
          onChange={(e) => setWritingSampleInput(e.target.value)}
        />
        <Divider className={classes.divider} />
        <Typography> Reason for taking Spanish:</Typography>
        <TextField
          id="reason-level-input-required"
          required
          label="Please Select Your Reason for taking Spanish"
          select
          value={reasonInput}
          defaultValue={reasonInput}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setReasonInput(e.target.value)}
        >
          {reasonOptions.map(
            (reasonOption: string) =>
              <MenuItem value={reasonOption}>
                {reasonOption}
              </MenuItem>
          )
          }
        </TextField>
        <Divider className={classes.divider} />
        <Typography> Previous level of Spanish Education:</Typography>
        <TextField
          id="previous-level-input-required"
          required
          label="Please Select Your Previous Level of Spanish Classes"
          select
          value={previousLevelInput}
          variant="outlined"
          defaultValue={previousLevelInput}
          fullWidth
          margin="normal"
          onChange={(e) => setPreviousLevelInput(e.target.value)}
        >
          {previousLevelOptions.map(
            (previousLevelOption: string) =>
              <MenuItem value={previousLevelOption}>
                {previousLevelOption}
              </MenuItem>
          )
          }
        </TextField>
        <Divider className={classes.divider} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {heritageResponses.map((curr_response) => <>
              <TableRow>
                <TableCell>{curr_response.text}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      () => setHeritageResponses(
                        heritageResponses.map((item: HeritageResponse) => {
                          if (item.text === curr_response.text) {
                            return {
                              text: item.text,
                              response: !curr_response.response
                            }
                          };
                          return item;
                        }
                        )
                      )
                    }

                  >
                    {(curr_response.response) ? "Yes" : "No"}
                  </Button>
                </TableCell>
              </TableRow>
            </>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <NavButton
          ready={buttonReady}
          color="secondary"
          text="Clear All Answers and Return to Beginning"
          effect={() => {
            setButtonReady(false)
            setProgress(0)
            setPosition(1)
            setHeritageResponses([])
          }}
        />
        <NavButton
          ready={buttonReady}
          color="primary"
          text="These Are Correct"
          effect={() => {
            subjectClassificationMutation({
              variables: {
                "heritageResponses": heritageResponses,
                "previousLevel": previousLevelInput,
                "reason": reasonInput,
                "token": tokenVal,
                "writingSample": writingSampleInput
              }
            })
            setPosition(position + 1)
          }
          }
        />
      </CardActions>
    </Card>
  )
};
