/*
 * This is the demographics form page, which will collect demographics options
 * via querying the subdomain and then produce a form to fill out for the student.
 */
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';

import { SUBJECT_DEMOGRAPHIC_QUERY } from '../gql/queries'
import { SUBJECT_DEMOGRAPHICS_FORM_SUBMIT_MUTATION } from '../gql/mutations'

// Styles, fairly rudimentary
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

// Main component
export default function DemographicsForm() {
  const history = useHistory();
  const tokenVal = sessionStorage.getItem('token');
  const { sid } = JSON.parse(tokenVal!.split(".")[0]);
  const [firstName, setFirstname] = useState('');
  const [age, setAge] = useState(0);
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [lastName, setLastName] = useState('');
  const [
    otherLanguagesSpoken, setOtherLanguagesSpoken
  ] = useState<string[]>([]);
  const [race, setRace] = useState(['']);
  const classes = useStyles();
  const [
    subjectDemographicsFormSubmitMutation
  ] = useMutation(SUBJECT_DEMOGRAPHICS_FORM_SUBMIT_MUTATION);
  // may wish to add loading to the constants from the query.
  const { error, data } = useQuery(SUBJECT_DEMOGRAPHIC_QUERY, {
    variables: {
      "token": tokenVal,
    },
    errorPolicy: 'all'
  });
  if (error) return <Typography variant="h6">Badness has occured, check console</Typography>;


  /** 
   * Some fields will require options to be selected from a list provided by
   * the client. This section generates loading icons for them and then the
   * components to allow them to dynamically render inside the main tsx form
   */
  let genderField = <CircularProgress />;
  let raceField = <CircularProgress />;
  let otherLanguagesSpokenField = <CircularProgress />;
  if (data) {
    genderField = (
      <TextField
        id="gender-input-required"
        required
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        label="Gender"
        select
        variant="outlined"
        margin="normal"
        fullWidth
      >
        {data.subject.clientSettings.demographicOptions.genderOptions.map(
          (genderOption: string) =>
            <MenuItem value={genderOption}>
              {genderOption}
            </MenuItem>
        )
        }
      </TextField>
    );
    raceField = (
      <TextField
        required
        id="race-input-required"
        value={race}
        onChange={
          (e: React.ChangeEvent<{ value: unknown }>) =>
            setRace(e.target.value as string[])
        }
        label="Race"
        defaultValue="Race"
        variant="outlined"
        select
        margin="normal"
        fullWidth
      >
        {
          data.subject.clientSettings.demographicOptions.raceOptions.map(
            (raceOption: string) =>
              <MenuItem value={raceOption}>
                {raceOption}
              </MenuItem>
          )
        }
      </TextField>
    );
    otherLanguagesSpokenField = (
      <TextField
        id="other-languages-spoken-input-required"
        value={otherLanguagesSpoken}
        onChange={
          (e: React.ChangeEvent<{ value: unknown }>) =>
            setOtherLanguagesSpoken(e.target.value as string[])
        }
        label="Other Languages Spoken"
        defaultValue="Other Languages Spoken"
        variant="outlined"
        select
        SelectProps={{ multiple: true }}
        margin="normal"
        fullWidth
      >
        {
          data.subject.clientSettings.demographicOptions.otherLanguagesSpokenOptions.map(
            (otherLanguagesSpokenOption: string) =>
              <MenuItem value={otherLanguagesSpokenOption}>
                {otherLanguagesSpokenOption}
              </MenuItem>
          )
        }
      </TextField>
    );
  }
  // This is the actual tsx
  return (
    <>
      <Container maxWidth="sm">
        <Paper className={classes.paper} elevation={3}>
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              subjectDemographicsFormSubmitMutation({
                variables: {
                  "token": tokenVal,
                  "age": age,
                  "email": email,
                  "firstName": firstName,
                  "lastname": lastName,
                  "otherLanguagesSpoken": otherLanguagesSpoken,
                  "gender": gender,
                  "race": race
                }
              })
              history.push('/classification')

            }}>
            <TextField
              required
              id="student-identification-read-only"
              label="Student ID"
              defaultValue={sid}
              InputProps={{ readOnly: true }}  // read only for obvious reasons
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Typography variant="h6">Please Answer The Following</Typography>
            <TextField
              required
              id="first-name-input-required"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
              label="First Name"
              defaultValue="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              required
              id="filled-required"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
              defaultValue="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              required
              id="email-input-required"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              defaultValue="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Divider className={classes.divider} />
            <TextField
              required
              id="age-input-required"
              value={age}
              type="number"
              onChange={(e) => setAge(parseInt(e.target.value, 10))}
              label="Age"
              defaultValue="Age"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            {genderField}
            {raceField}
            {otherLanguagesSpokenField}
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );

}

