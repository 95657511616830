import React, { ReactElement } from "react";
import { QueryResult } from '@apollo/client';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import { MenuItem } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

type LanguageSelectProps = {
  examLanguage: string,
  setExamLanguage: Function,
  clientSettings: QueryResult<any, { client: string; }>,
  classes: ClassNameMap
}

export default function LanguageSelect(
  {
    examLanguage,
    setExamLanguage,
    clientSettings,
    classes,
  }: LanguageSelectProps
): ReactElement {
  /**
   * React component that acts as an MUI Select component with additional logic
   * to handle loading query data.
   * 
   * @remarks Since the contents of the language selection dropdown require
   * querying the db, there will be some (hopefully small) time between the
   * login view loading and the query being ready. Therefore this has logic to
   * handle the loading case (and return a message and circular progress
   * button), and then when the query has returned data, map the
   * clientSettings.data.clientLanguages to a list of MenuItems to populate
   * the dropdown, and then return the form control dropdown using that (and
   * with the useState hook passed down to allow it to update state properly).
   * 
   * @param examLanguage - string representing the exam's language, which this
   * component tracks an sets the state of. Derived from one of SignInSubject's
   * useState hooks. 
   * @param setExamLanguage - the function to set examLanguage. Derived from
   * one of SignInSubject's useState hooks. 
   * @param clientSettings - the query result object passed down from
   * SignInSubject's useQuery hook.
   * @param classes - classNameMap passed from SignInSubject to allow passing
   * down css styling
   * 
   * @returns a React element that either consists of a loading indicator and
   * message OR a dropdown menu using the language list derived from
   * clientSettings.
   */

  // Handle the clientSettings query not having fully processed yet
  if (clientSettings.loading) {
    return (<div>
      <Typography align='center'><i>Loading Languages...</i></Typography>
      <CircularProgress className={classes.loading} />
    </div>);
  };

  // map the languages to a list of MenuItems
  const languageList = clientSettings.data.clientLanguages.map(
    (languageOption: string) =>
      <MenuItem className={classes.language} value={languageOption}>
        {languageOption}
      </MenuItem>
  );
  //  return the element
  return (<>
    <FormControl margin="normal" required fullWidth>
      <InputLabel htmlFor="examLanguage">Language</InputLabel>
      <Select
        name="examLanguage"
        value={examLanguage}
        type="examLanguage"
        id="examLanguage"
        autoComplete="current-examLanguage"
        onChange={(e) => {
          setExamLanguage(e.target.value as string);
        }
        }
      >
        {languageList}
      </Select>
    </FormControl>
  </>)
}