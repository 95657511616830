/*
 * Extremely Basic Context system. 
 * 
*/
import { createContext, useContext } from 'react';

// As the man says, the Typescript toll

export type GlobalContent = {
  loggedIn: boolean
  user: string
  language: string
  adminStatusLoggedIn: boolean
  setLoggedStatus:(c: boolean) => void
  setUser:(c: string) => void
  setAdminStatus:(c: boolean) => void
  setLanguage: (c: string) => void
  specialChars: string[]
  setSpecialChars: React.Dispatch<React.SetStateAction<string[]>>
}

// just using the types to create a Context Object that can be imported

export const MyContext = createContext<GlobalContent>({
loggedIn: false, // set a default value
setLoggedStatus: () => {},
user: 'Please Sign In', // set a default value
setUser: () => {},
language: '',
adminStatusLoggedIn: false,
setAdminStatus: () => {},
setLanguage: () => {},
specialChars: [''],
setSpecialChars: () => [],
})

// the hook to grab the context, that actually gets imported. 

export const useMyContext = () => useContext(MyContext);