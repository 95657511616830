import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { SubjectLanguageObject } from '../../gql/schemas';


type HeritageClassificationTabProps = {
  classes: any,
  languages: SubjectLanguageObject[],
}


export default function HeritageClassificationTab({
  classes,
  languages
}: HeritageClassificationTabProps) {
  /**
   * Takes a list of SubjectLanguageObjects
   */
  return (<>
    {languages.map(
      (
        {
          writingPrompt,
          writingSample,
          reason,
          previousLevel,
          heritageResponses,
          heritagePnts
        }: SubjectLanguageObject
      ) => (
        <>
          <Grid item xs={6}>
            <Card className={classes.root}>
              <CardContent>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: writingPrompt }} />
                <TextField
                  className={classes.text}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                  value={writingSample}
                />
                <Divider />
                <TextField
                  className={classes.text}
                  id="reason-level-input-required"
                  inputProps={{ readOnly: true }}
                  required
                  label="Please Select Your Reason for taking Spanish"
                  select
                  value={reason}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={reason}>{reason}</MenuItem>
                </TextField>
                <TextField
                  className={classes.text}
                  id="previous-level-input-required"
                  inputProps={{ readOnly: true }}
                  required
                  label="Please Select Your Previous Level of Spanish Classes"
                  select
                  value={previousLevel}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={previousLevel}>{previousLevel}</MenuItem>
                </TextField>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.root}>
              <CardContent>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {heritageResponses?.map(({ text, response }) => <>
                      <TableRow>
                        <TableCell>{text}</TableCell>
                        <TableCell>
                          <Button variant="contained">
                            {(response) ? "Yes" : "No"}
                          </Button>
                        </TableCell>
                      </TableRow></>
                    )}
                  </TableBody>
                </Table>
                <Typography align="center" variant="h6">
                  Heritage Response Questions Score: {heritagePnts}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      ))}
  </>
  )
};