/*
 * This view displays the instructor sign in page with the header.  The sign in
 * 
 */
import React from 'react';

import SignInInstructor from '../../components/login/SignInInstructor'

function Instructor() {
  return (
    <>
      <SignInInstructor />
    </>
  );
};

export default Instructor;