import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';


import { useQuery } from '@apollo/client';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';


import { ResponseDAO, SubjectDAO } from 'gql/schemas';
import { INSTRUCTOR_EXAM_VIEW_QUERY } from 'gql/queries';
import HeritageClassificationTab from 'components/instructor/HeritageClassificationTab';
import NotLoggedInAsInstructor from 'components/instructor/NotLoggedInAsInstructor';
import ResponseViewSectionParser from 'components/instructor/ResponseViewSectionParser';
import ResponseQuestionsArrayConstructor, { QuestionSchema } from 'components/instructor/ResponseQuestionsArrayConstructor';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      padding: 10,
    },
    item: {
      margin: theme.spacing(1),
      alignItems: "center"
    },
    text: {
      margin: theme.spacing(1),
    },
    textField: {
      margin: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 'fit-content',
    },
    questionElement: {
      margin: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    sideBySide: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    }

  }),
);

export default function ResponseView() {
  /**
   * This function generates a viewable student exam response.
   * 
   * @remarks 
   * This is the component called by the router for viewing student responses,
   * which will grab the subjectId (student id) from the URL, grab the auth
   * of the instructor doing the viewing from local storage, then perform a
   * graphQL query to get that subjectId's response. If the user isn't
   * authorized, the query hasn't finished loading, or there's been qa query
   * error, it will display appropriate messaging. Otherwise, it will process
   * the query, grab the ResponseDAO from data.instructor.responsesBySubjectId
   * and pass the appropriate parts to ResponseQuestionsArrayConstructor which
   * will return an array of QuestionSchema objects, then pass that along with
   * the css classes and the ResponseDAO's sections to the (long-named)
   * ResponseViewSectionParser which will construct an array of components that
   * this will render (along with the student's heritage classification tab)
   * in a grid under their name.
   * 
   * @returns a JSX Element
   */
  const classes: ClassNameMap = useStyles();
  // access subject id from URL
  const { subjectId } = useParams<{ subjectId: string }>()
  // grab instructor token from local storage
  const tokenVal: string | null = localStorage.getItem('instructorToken')
  // query for responses
  const { data, error, loading } = useQuery(
    INSTRUCTOR_EXAM_VIEW_QUERY, {
    variables: {
      "token": tokenVal,
      "subjectId": subjectId
    },
  },
  );
  // check if logged in (after hooks because rules of hooks)
  if (!tokenVal) {
    return <NotLoggedInAsInstructor />
  };
  // Conditionally return loading/error screens as necessary
  if (loading) {
    return <>
      <Typography>Finishing loading exam...</Typography><CircularProgress />
    </>
  };
  if (error) {
    return <Typography>We are sorry, there has been an error.</Typography>
  };

  // Parse the query data to use for rendering the student information
  const subject: SubjectDAO = data?.instructor?.responsesBySubjectId[0]?.subject?.subject;
  const responses: ResponseDAO[] | null = data?.instructor?.responsesBySubjectId;

  // stub so I can view an response object and mess around with it in browser
  // TODO: cut this, there's no need for it
  let responseString: string;
  if (responses) {
    responseString = JSON.stringify(responses![0]);
  } else {
    responseString = "oops";
  };
  sessionStorage.setItem('response', responseString);

  // Create questions/sections, populate once responses is non-null
  let questions: Array<QuestionSchema>;
  let allSections: Array<ReactElement> = [];
  if (responses) {
    const response: ResponseDAO = responses[0];
    const { sections, tsStarted } = response;
    questions = ResponseQuestionsArrayConstructor(response.items);
    allSections = ResponseViewSectionParser(
      { classes, sections, questions, tsStarted }
    )
  };



  // display the response
  return (
    <>
      <Typography variant="h3">
        Exam response for {subject.firstName} {subject.lastName}
      </Typography>
      <Grid
        className={classes.grid}
        container
        alignItems="flex-start"
        direction="row"
      >
        <HeritageClassificationTab
          classes={classes}
          languages={subject.languages}
        />
        {allSections}
      </Grid>
    </>
  );
};

