/* 
 * The heritage questions are served after the disclaimer and writing prompt cards,
 * and ask a series of yes/no questions to collect information about the subject's
 * experiences as a heritage language speaker.
 *
 * The heritage questions come from the database as an array, the component here
 * takes heritagePosition as an argument render each one and display the text, as well
 * as NavButtons for Back, Yes, and No. The yes/no buttons will push a dict of
 * {text: string, response: boolean} corresponding to the text of the current
 * question and the chosen button to the heritageResponses array that will be used
 * in the Classification mutation n src/views/Classification
 */
import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import NavButton from './NavButton';



/*
 * Schema for heritage response questions and responses- note that this only includes the fields
 * accessible and mutatable by this form
 */
interface HeritageQuestion {
  text: string
}

// exporting b/c other files will need this to define inputs
export interface HeritageResponse {
  text: string;
  response: boolean;
}

type HeritageQuestionCardProps = {
  heritagePosition: number,
  classes: any,
  heritageQuestions: HeritageQuestion[],
  heritageResponses: HeritageResponse[],
  buttonReady: boolean,
  setButtonReady: Function,
  setProgress: Function,
  position: number,
  setPosition: Function,
}

// not exported as default because also exporting interface
export function HeritageQuestionCard({
  heritagePosition,
  classes,
  heritageQuestions,
  heritageResponses,
  buttonReady,
  setButtonReady,
  setProgress,
  position,
  setPosition,
}: HeritageQuestionCardProps
) {
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6">
          Please answer yes or no truthfully
        </Typography>
        <Divider className={classes.divider} />
        <Typography>{heritageQuestions[heritagePosition].text}</Typography>
      </CardContent>
      <CardActions>
        <NavButton
          ready={buttonReady}
          text="Back"
          color="secondary"
          effect={() => {
            setButtonReady(false)
            setProgress(0)
            setPosition(position - 1)
            heritageResponses.pop()
          }}
        />
        <NavButton
          ready={buttonReady}
          text="Yes"
          color="primary"
          effect={() => {
            setButtonReady(false)
            setProgress(0)
            heritageResponses.push(
              {
                text: heritageQuestions[heritagePosition].text, response: true
              }
            )
            setPosition(position + 1)
          }}
        />
        <NavButton
          ready={buttonReady}
          text="No"
          color="primary"
          effect={() => {
            setButtonReady(false)
            setProgress(0)
            heritageResponses.push(
              {
                text: heritageQuestions[heritagePosition].text, response: false
              }
            )
            setPosition(position + 1)
          }}
        />
      </CardActions>
    </Card>
  )
};