import { ResponseItemSchema } from "gql/schemas";

export type QuestionSchema = {
  text: string | null,
  itemId: string,
  sectionId: string,
  answers: ResponseItemSchema[],
};


export default function ResponseQuestionsArrayConstructor(
  items: ResponseItemSchema[]
): QuestionSchema[] {
  /**
   * Given an array of ResponseItemSchema objects, returns an array of
   * QuestionSchema objects.
   * 
   * @remarks
   * Creates an empty mapping of itemId:QuestionSchema objects, then
   * iterates over the items array, and checks if each item's itemId is
   * already in the usedItems set. If this item's question already exists,
   * then the item is pushed to the end of that question's answers array.
   * If not, it sets a new itemId:QuestionSchema pair and includes itself as
   * the first entry in the new object's answers array.
   * Once all the items have been iterated over, it no longer needs the keys
   * for reference and so returns an array constructed from the values.
   * 
   * @param items - the array of ResponseItemSchema objects to be iterated
   * over
   * 
   * @returns An array of QuestionSchema objects
   */
  const questionContainer = new Map<string, QuestionSchema>();
  const usedItems = new Set();
  items.forEach(
    (item: ResponseItemSchema) => {
      // destructuring to be easier on the eyes
      const { item: { question }, itemId, sectionId } = item
      if (usedItems.has(itemId)) {
        questionContainer.get(itemId)!.answers.push(item)
      } else {
        questionContainer.set(
          itemId, {
          text: question,
          itemId,
          sectionId,
          answers: [item]
        }
        );
        usedItems.add(itemId);
      }
    });
  return Array.from(questionContainer.values());
};