import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { ResponseDAO } from '../../gql/schemas';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grid: {
      padding: 10,
    },
    text: {
      marginBottom: theme.spacing(1),
      readOnly: true,
    },
    row: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    table: {
    },
  }),
);

type ResponseRowProps = {
  row: ResponseDAO
};

export default function ResponseRow(
  {
    row: {
      assessment,
      language,
      subject: {
        firstName,
        lastName,
        id,
        email
      },
      tsCompleted,
      tsStarted
    }
  }: ResponseRowProps
): JSX.Element {
  /**
   * Constructs a TableRow from a ResponseDAO, allowing for display of the info
   * about a response, and including a button to open a new tab displaying the
   * response.
   * 
   * @remarks
   * Takes a ResponseDAO and after destructuring and lightly uses the data to
   * populate a TableRow full of TableCells, which contain (in order): a
   * button to navigate to the full response view page, the response id,
   * first name, last name, email, the language the response is in, the
   * assessment (or incomplete if it's missing), the time the response was
   * started (which is converted from Date to string for display), and either
   * the completion timestamp (also converted for display) or if tsCompleted
   * is falsey, an string indicating incompleteness.
   * 
   * @param row - ResponseDAO destructured into remaining params
   * @param assessment - string indicating the grade
   * @param language - string indicating the language of the response
   * @param subject - SubjectDAO destructured into id, firstName, lastName, and
   * email (which follow this), not used by itself.
   * @param id - string indicating the subject id (student id not objectid)
   * @param firstName - string indicating first name of subject
   * @param lastName - string indicating last name of subject
   * @param email - string indicating email of subject
   * @param tsCompleted - Date object indicating time response was completed
   * if any
   * @param tsStarted - Date object indicating time response was created
   *  
   * @returns JSX Element with a TableRow and TableCell elements corresponding
   * to the row data, as well as a TableCell containing a button to open a new
   * tab displaying the response.
   */
  const classes = useStyles();
  const created = DateTime.fromJSDate(
    new Date(tsStarted)
  ).toLocaleString(DateTime.DATETIME_FULL)
  const completed = tsCompleted ? DateTime.fromJSDate(
    new Date(tsCompleted)
  ).toLocaleString(DateTime.DATETIME_FULL) : "Incomplete"
    ;

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <Link
            to={{
              pathname: `/response_view/${id}`,
              state: { slug: { id } },
            }}
            target="_blank"
          >
            <OpenInNewIcon />
          </Link>
        </TableCell>
        <TableCell component="th" scope="row">
          {id}
        </TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{language}</TableCell>
        <TableCell>{assessment ?? "Incomplete"}</TableCell>
        <TableCell>{created}</TableCell>
        <TableCell>{completed}</TableCell>
      </TableRow>
    </>
  );
};
