/*
 * Instructor Dashboard allows instructors to search for students and then display their exam results
 */
import React, { useState, } from 'react';
import { DateTime } from 'luxon';
import { useLazyQuery, } from '@apollo/client';

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import NotLoggedInAsInstructor from 'components/instructor/NotLoggedInAsInstructor';
import ResponseRow from 'components/instructor/ResponseRow';
import CSVParser from 'components/instructor/CSVParser';
import { ResponseDAO } from 'gql/schemas';
import {
  INSTRUCTOR_RESPONSES_BY_NAME_QUERY,
  INSTRUCTOR_RESPONSES_BY_SUBJECT_ID_QUERY,
  INSTRUCTOR_RESPONSES_BY_DATE_QUERY,
} from "gql/queries";


const useStyles: any = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    button: {
      display: 'flex',
      alignItems: 'middle',
      flexDirection: 'column',
    },
    iconButton: {
      padding: 10,
    },
    table: {
    },
    title: {
      flexGrow: 1,
    },
    textField: {
      width: '25ch',
    },
  }),
);


export default function InstructorMain() {
  /**
   * Renders Instructor Dashboard
   */
  const classes = useStyles();
  // auth
  const tokenVal = localStorage.getItem('instructorToken');
  // variables for search
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateFirstName, setDateFirstName] = useState('');
  const [dateLastName, setDateLastName] = useState('');
  const [subjectId, setSubjectId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // container for the search results from either query
  const [queryStub, setQueryStub] = useState<ResponseDAO[]>();
  /*
   * queries, note that this is Lazy Query so it doesn't fire on page load, but
   * wait to query until call however nextFetchPolicy: standby is necessary to
   * prevent refiring while rerendering
   */
  // TODO: handle errors/loading
  const [searchMultipleStudents,] = useLazyQuery(
    INSTRUCTOR_RESPONSES_BY_NAME_QUERY, {
    nextFetchPolicy: 'standby',
    errorPolicy: 'ignore',
    onCompleted: data => setQueryStub(data?.instructor?.responsesByName)
  }
  );
  const [searchSingleStudent,] = useLazyQuery(
    INSTRUCTOR_RESPONSES_BY_SUBJECT_ID_QUERY, {
    nextFetchPolicy: 'standby',
    errorPolicy: 'ignore',
    onCompleted: data => setQueryStub(data?.instructor?.responsesBySubjectId)
  });
  const [searchResponsesbyDate,] = useLazyQuery(
    INSTRUCTOR_RESPONSES_BY_DATE_QUERY, {
    nextFetchPolicy: 'standby',
    errorPolicy: 'ignore',
    onCompleted: data => setQueryStub(data?.instructor?.responses)
  });
  // check if logged in (after hooks because rules of hooks)
  if (!tokenVal) {
    return <NotLoggedInAsInstructor />
  };
  // display username
  const user = JSON.parse(tokenVal!.split(/({".+"})/)[1]).iid;

  // process response rows into an array of jsx
  const responseRows: JSX.Element[] | undefined = queryStub?.map(
    (row: ResponseDAO) => (
      <ResponseRow row={row} />
    )
  );
  // process query results into csv for export


  // render
  return (
    <>
      <Typography variant="h6" color="secondary" className={classes.title}>
        {user}
      </Typography>
      {/* This section renders the search form */}
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        className={classes.root}
      >
        <Grid item xs={6}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography align='center' variant="h6">
                Search By First or Last Name
              </Typography>
              <Typography>
                Will return exams by students with matching names.
              </Typography>
            </CardContent>
            <CardActions>
              <form
                id="name-search-form"
                autoComplete="off"
                onSubmit={(event: any) => {
                  event.preventDefault();
                  searchMultipleStudents(
                    {
                      variables:
                      {
                        "token": tokenVal,
                        "firstName": firstName,
                        "lastName": lastName,
                      },
                    },
                  );
                }}
              >
                <TextField
                  className={classes.textField}
                  id="first-name"
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  type="search"
                  size="small"
                  value={firstName}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  id="last-name"
                  label="Last Name (Required)"
                  onChange={(e) => setLastName(e.target.value)}
                  size="small"
                  type="search"
                  required
                  value={lastName}
                  variant="outlined"
                />
                <IconButton
                  id="multi-form-submit"
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </form>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography align='center' variant="h6">
                Search by Student ID
              </Typography>
              <Typography>
                Will return exams from only the student with matching student id.
              </Typography>
            </CardContent>
            <CardActions>
              <form
                id="id-search-form"
                noValidate
                autoComplete="off"
                onSubmit={
                  (event: any) => {
                    event.preventDefault();
                    searchSingleStudent({
                      variables:
                      {
                        "token": tokenVal,
                        "subjectId": subjectId
                      }
                    });
                  }
                }
              >
                <TextField
                  className={classes.textField}
                  id="subject-id"
                  label="Student ID"
                  onChange={(e) => setSubjectId(e.target.value)}
                  size="small"
                  type="search"
                  value={subjectId}
                  variant="outlined"
                />
                <IconButton
                  id="single-form-submit"
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </form>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" align='center'>
                Search by Date
              </Typography>
              <Typography>
                Will return exams by date. Optional name fields will narrow the search.
              </Typography>
            </CardContent>
            <CardActions>
              <form
                id="date-search-form"
                noValidate
                autoComplete="off"
                onSubmit={
                  (event: any) => {
                    event.preventDefault();
                    searchResponsesbyDate({
                      variables:
                      {
                        "token": tokenVal,
                        "firstName": dateFirstName,
                        "lastName": dateLastName,
                        "startDate": DateTime.fromISO(startDate),
                        "endDate": DateTime.fromISO(endDate),

                      }
                    });
                  }
                }
              >
                <Container>
                  <TextField
                    className={classes.textField}
                    id="date-first-name"
                    label="First Name"
                    onChange={(e) => setDateFirstName(e.target.value)}
                    type="search"
                    size="small"
                    value={dateFirstName}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    id="date-last-name"
                    label="Last Name"
                    onChange={(e) => setDateLastName(e.target.value)}
                    size="small"
                    type="search"
                    value={dateLastName}
                    variant="outlined"
                  />
                </Container>
                <Container>
                  <TextField
                    className={classes.textField}
                    id="start-date"
                    label="Start Date"
                    onChange={(e) => setStartDate(e.target.value)}
                    size="small"
                    type="date"
                    value={startDate}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    id="end-date"
                    label="End Date"
                    onChange={(e) => setEndDate(e.target.value)}
                    size="small"
                    type="date"
                    value={endDate}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <IconButton
                    id="single-form-submit"
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Container>
              </form>
            </CardActions>
          </Card>
          <Button
            variant='contained'
            id="multi-form-submit-csv"
            className={classes.button}
            color='primary'
            aria-label="export"
            fullWidth
            disabled={!(queryStub)} // no point saving an empty sheet
            onClick={()=>CSVParser(queryStub as ResponseDAO[])}
            startIcon={<SaveIcon />}
          >
            Save current results as CSV file.
          </Button>
        </Grid>
        {/* Now render the data */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="student-table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>View Exam</TableCell>
                  <TableCell>Student ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Languages</TableCell>
                  <TableCell>Assessment</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Here is where the actual data rows are */}
                {responseRows}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

