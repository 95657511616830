import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import QuestionRegex, { IRResponseMap } from './QuestionRegex';
import MediaLoader, { MediaSchema } from "./MediaLoader";

type SelectOption = {
  entry: string,
  values: string[],
}

export type SectionItemProps = {
  classes: ClassNameMap,
  signature: string,
  setInputName: Function,
  setTextInputFocused: Function,
  irResponseObject: IRResponseMap,
  handleChange: Function,
  setLayoutName: Function,
  question: string,
  instructions: string | null,
  media: MediaSchema | null,
  selectOptions: SelectOption[],
  radioOptions: string[]
  textArea: boolean,
}



export default function SectionItem({
  classes,
  signature,
  setInputName,
  setTextInputFocused,
  irResponseObject,
  setLayoutName,
  handleChange,
  question,
  instructions,
  media,
  selectOptions,
  radioOptions,
  textArea,
}: SectionItemProps): JSX.Element {
  /**
   * Takes the individual SectionDAO item, uses regex to parse the question
   * into an array of strings (questionText), then loops over them to push into
   * another array (questionFields) which it will use to render the actual exam
   * question, along with any other props like instructions/media/etc.
   * 
   * @remarks
   * The loop logic tests whether each string in the array starts with "{" or
   * not. 
   * If not, it pushes it to the end of questionFields as a <div> compoent
   * rendering it as html (this is at the end of the loop's conditional logic, 
   * and so is a bit non-obvious, apologies).
   *
   * If it does start with "{", it strips the first and last two characters out
   * (eg: "{{box1}} becomes "box1"). It then performs a find operation on the
   * selectOptions argument (passed down from the ItemDAO.selectOptions field
   * by Exam.tsx) for an object with an entry field that matches the
   * now-stripped string. That object, or "text" if the find operation returns
   * as null (which it will if there is no match), will be assigned to
   * nameFound.
   *
   * nameFound is then used to decide which form input component to generate. 
   * If it's "text", a text input box is created, otherwise nameFound.values
   * is used to populate the select options for a select box component.
   * 
   * For the input component to be a controlled component, it needs a value
   * (that is, it needs to point at a part of the form state). 
   * 
   * All parts of an ItemDAO share the same signature, which is passed down
   * to SectionItem from Exam.
   * 
   * The entryId is used to separate multiple inputs from the same question and
   * at this time maps to the contents of the bracketed strings, eg:
   * if "{{box1}}" the entryId will be "box1".
   * 
   * Therefore, each field is assigned a value (question id, "qid") based on
   * the signature, and then if the parent ItemDAO has more than one field,
   * appending the entryId.
   * 
   * The handleChange function in the input elements replaces the current 
   * irResponses object with one such that key matching signature/question id
   * as described above has it's 'responses' field updated, or if no such
   * element exists, it adds one.
   *
   * After the loop, it checks the ItemDAO's radioOptions field and uses that
   * to populate a radioGroup element (if the field is empty the element will
   * be). This is also where media/etc will be generated. 
   *
   * After all that, it will render the instructions, the questionFields array
   * generated by the loop, the radioFields, any other fields necessary (which
   * currently do not exist), then a divider.
   * 
   * @param classes - classNameMap for passing down CSS
   * @param signature - string corresponding to ItemDAO signature
   * @param setInputName - dispatch function for updating inputName state,
   * which the virtual keyboard uses to track the part of the irResponse object
   * it should be modifying
   * @param setTextInputFocused - dispatch function that updates
   * textInputFocused, which triggers displaying the virtual keyboard when
   * true
   * @param irResponseObject - IRResponseMap object that holds the different
   * question responses, the thing these inputs are updating
   * @param setIrResponseObject - dispatch function to update irResponseObject
   * @param setLayoutName - dispatch function to update the virtual keyboard
   * layout, in this case switching from upper to lower case
   * @param handleChange - dispatch function to handle changes in the inputs,
   * passed down from exam so it can be at the top level
   * @param question - string representing the question, passed down from the
   * ItemDAO, that is then processed by QuestionRegex and then turned into
   * text/select inputs
   * @param instructions - string with instructions for the whole section,
   * or null if there are none
   * @param media - MediaSchema object containing media for the section (such
   * as audio for the dictation section), or null if there is no media
   * @param selectOptions - array of SelectOption objects used to populate any
   * select inputs in the section
   * @param radioOptions - array of strings (possibly empty) used to populate
   * the radio button fields
   * @param textArea - boolean representing whether this is an essay section
   * 
   * @returns JSX elements: A grid item containing the instructions, media,
   * a smaller grid with the questions, the radio fields, and lastly a divider
   */


  const questionFields: JSX.Element[] = [];
  const questionText: string[] = QuestionRegex(question);
  questionText.forEach(
    (s: string) => {
      if (s.slice(0, 2) === "{{" && s.slice(-2) === "}}") {
        const name: string = s.slice(2, -2).trim()
        // if no selectOptions, this next line will evaluate to null
        const nameFound = selectOptions?.find((item) => (item.entry === name));
        const nameValues = nameFound?.values ?? "text";
        const qid = `${signature}${name}`
        if (nameValues === "text") {
          // text input element
          questionFields.push(
            <TextField
              id={qid}
              className={classes.inputBox}
              required
              variant="outlined"
              size="small"
              margin="normal"
              value={irResponseObject[qid]?.response}
              onChange={(e) => handleChange(e, qid)}
              onFocus={() => { setInputName(qid); setTextInputFocused(true) }}
              // actual keyboard shift should affect virtual keyboard
              onKeyDown={(e) => (e.key === "Shift") ? setLayoutName("shift") : null}
              onKeyUp={(e) => (e.key === "Shift") ? setLayoutName("default") : null}
            />
          )
        } else {
          questionFields.push(
            // select input element
            <TextField
              id={qid}
              className={classes.inputBox}
              select
              required
              variant="outlined"
              size="small"
              margin="normal"
              value={irResponseObject.qid?.response}
              onChange={(e) => handleChange(e, qid)}
              onFocus={() => { setInputName(qid); setTextInputFocused(false) }}
            >
              {
                nameValues.map(
                  (nameOption: string) =>
                    <MenuItem value={nameOption}>{nameOption}</MenuItem>
                )}
            </TextField>
          )
        }
      } else {
        questionFields.push(
          // text for a writing section
          /* eslint-disable-next-line react/no-danger */
          <div dangerouslySetInnerHTML={{ __html: s }} />)
      }
    });
  if (textArea) {
    // long text entry for an essay/etc
    questionFields.push(
      <TextField
        id={signature}
        className={classes.longTextField}
        required
        variant="outlined"
        margin="normal"
        multiline
        rows={8}
        fullWidth
        rowsMax={Infinity}
        value={irResponseObject[signature]?.response}
        onChange={(e) => handleChange(e, signature)}
        onFocus={() => { setInputName(signature); setTextInputFocused(true) }}
        // actual keyboard shift should affect virtual keyboard
        onKeyDown={(e) => (e.key === "Shift") ? setLayoutName("shift") : null}
        onKeyUp={(e) => (e.key === "Shift") ? setLayoutName("default") : null}
      />
    )
  };
  // the radioButtons/radioFields handle radio button fields (a)
  const radioButtons = radioOptions?.map(
    (item: string) =>
      <FormControlLabel
        value={item}
        control={
          <Radio required />
        }
        label={item}
      />
  );
  const radioFields =
    <>
      <Grid className={classes.sectionGrid} container>
        <RadioGroup
          id={signature}
          name={question}
          value={irResponseObject.signature}
          onFocus={() => { setInputName(signature); setTextInputFocused(false) }}
          onChange={(e) => handleChange(e)}
        >
          {radioButtons}
        </RadioGroup>
      </Grid>
    </>
    ;

  return (
    <Grid>
      <Typography>{instructions}</Typography>
      {media ? MediaLoader(media) : <></>}
      <Grid className={classes.sectionGrid}>
        {questionFields}
      </Grid>
      {radioOptions ? radioFields : <></>}
      <Divider />
    </Grid>
  );
};

