import gql from 'graphql-tag';


export const SUBJECT_DEMOGRAPHIC_QUERY = gql`
    query subject($token: String) {
      subject(token:$token) {
        clientSettings {
          demographicOptions {
            raceOptions
            genderOptions
            reasonOptions
            previousLevelOptions
            otherLanguagesSpokenOptions
          }
        }  
      }
    }`;

export const CLASSIFICATION_QUERY = gql`
    query subject($token: String) {
      subject(token: $token) {
        clientSettings{
          specialChars{
            upper
            lower
          }
          demographicOptions{
            reasonOptions
            previousLevelOptions
          }
          heritageQuestions{
            text
          }
          disclaimer
          writingPrompt
        }
      }
    }
`;

export const SUBJECT_IR_QUERY = gql`
query subject($subjectToken: String, $responseId: MongoID! ) {
  subject(token: $subjectToken) {
    subject{
      firstName
      lastName
    }
    clientSettings{
      specialChars{
        upper
        lower
      }
    }
    ir(responseId:$responseId){
      id
      title
      des
      instruction
      inert
      examId
      items{
        id
        signature
        bankId
        examId
        vars
        ans{
          entry
          pnt
          val
        }
        stats
        media {
          name
          caption
          type
          file
        }
        question
        textArea
        instructions
        selectOptions{
          entry
          values
        }
        radioOptions
        inert
      }
    }
  }
}`;

export const SUBJECT_COMPLETED_RESPONSE_QUERY = gql`
  query subject($token: String) {
    subject(token: $token) {
      completed{
        message
        level
        tsStarted
        tsCompleted
      }
    }
  }`;

export const INSTRUCTOR_SUBJECTS_QUERY = gql `
  query AuthQuery(
    $token: String,
    $startDate:DateTime,
    $endDate: DateTime,
    $lastName:String,
    $firstName:String,
  ) {
  instructor(token: $token) {
    subjects(
          startDate:$startDate, endDate:$endDate, lastName: $lastName, firstName:$firstName
    ) {
      id
      firstName
      lastName
      gender
      age
      email
      otherLanguagesSpoken
      race
      createdTs
      updatedTs
    }
  }
}`;

export const INSTRUCTOR_SUBJECT_QUERY = gql `
  query AuthQuery(
  $token: String,
  $subjectId:String!
) {
instructor(token: $token) {
  subject(
    subjectId:$subjectId
  ) {
    id
    firstName
    lastName
    gender
    age
    email
    otherLanguagesSpoken
    race
    createdTs
    updatedTs
  }
}
}`;

export const INSTRUCTOR_EXAM_VIEW_QUERY = gql `
query AuthQuery($token: String!, $subjectId: String!) {
  instructor(token: $token) {
    responsesBySubjectId(subjectId: $subjectId) {
      score
      examId
      subject {
        subject {
          firstName
          lastName
          languages {
            language
            writingPrompt
            writingSample
            previousLevel
            heritageResponses {
              text
              response
              pnts
            }
            previousPlacementLevel
            reason
            heritagePnts
          }
        }
      }
      id
      tsStarted
      tsCompleted
      language
      classification
      assessment
      items {
        response
        pnts
        sectionId
        itemId
        item {
          question
          instructions
        }
      }
      sections {
        ts
        pnts
        sectionId
        section {
          des
          instruction
        }
      }
    }
  }
}
  `;


export const INSTRUCTOR_RESPONSES_BY_SUBJECT_ID_QUERY = gql `
query AuthQuery($token: String, $subjectId: String!) {
  instructor(token: $token) {
    responsesBySubjectId(subjectId: $subjectId) {
      subject {
        id
        firstName
        lastName
        email
      }
      language
      assessment
      tsStarted
      tsCompleted
      id
      score
    }
  }
}
`;


export const INSTRUCTOR_RESPONSES_BY_NAME_QUERY = gql`
query AuthQuery($token: String, $lastName: String!, $firstName: String, $examIds: [MongoID], $language: String) {
  instructor(token: $token) {
    responsesByName(lastName: $lastName, firstName: $firstName, examIds: $examIds, language: $language) {
      subject {
        id
        firstName
        lastName
        email
      }
      language
      assessment
      tsStarted
      tsCompleted
      id
      score
    }
  }
}
`;

export const INSTRUCTOR_SECTION_BY_SECTIONID_QUERY = gql `
  query AuthQuery(
    $token: String, $sectionID: [String]!) {
      instructor(token: $token) {
        sectionBySectionId(sectionId: $sectionID) {
            id
            title
            des
            instruction
            inert
            examId
            items{
              id
              signature
              bankId
              examId
              vars
              ans{
                entry
                pnt
                val
              }
              stats
              media {
                name
                caption
                type
                file
              }
              question
              textArea
              instructions
              selectOptions{
                entry
                values
              }
              radioOptions
              inert
            }
          }
      }
    }
`;

export const INSTRUCTOR_RESPONSES_BY_DATE_QUERY = gql`
query AuthQuery(
  $token: String,
  $firstName: String,
  $lastName: String,
  $startDate: DateTime,
  $endDate: DateTime,
) {
    instructor(token: $token) {
      responses(
        firstName: $firstName,
        lastName: $lastName
        startDate: $startDate,
        endDate: $endDate,
      ) {
        subject {
          firstName
          lastName
          email
          id
        }
        language
        tsStarted
        tsCompleted
        assessment
        id
        score
      }
    }
  }
`;

export const LOGIN_CLIENT_LANGUAGE_QUERY = gql`
  query AuthQuery(
    $client:String!
  ) {
    clientLanguages(client:$client)
  }
`;
