/*
 * The disclaimer card is the first question served by the classification view. It
 * doesn't require any information to be kept, since navigating past it signifies 
 * acceptance.
 * The component renders the html from the client settings disclaimer, and then
 * Back / Accept versions of the NavButton component.
 */
import React from 'react'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import NavButton from './NavButton';


type DisclaimerCardProps = {
  classes: any,
  disclaimer: string,
  buttonReady: boolean,
  setButtonReady: Function,
  setProgress: Function,
  position: number,
  setPosition: Function,
}
export default function DisclaimerCard(
  {
    classes,
    disclaimer,
    buttonReady,
    setButtonReady,
    setProgress,
    position,
    setPosition,
  }: DisclaimerCardProps
) {
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4">
          Please read the following carefully before continuing.
        </Typography>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <Divider className={classes.divider} />
      </CardContent>
      <CardActions>
        <NavButton
          ready={buttonReady}
          text="Accept"
          color="primary"
          effect={() => {
            setButtonReady(false)
            setProgress(0)
            setPosition(position + 1)
          }}
        />
      </CardActions>
    </Card>
  )
};
