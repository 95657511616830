/*
 * index file is the base render and entry point into the application
 * where the apollo client and context is defined.  This view should never rerender
 * and only children components should rerender.
 *
 * The Apollo client is a globally accessible system for making connections to the 
 * apollo system from any component without having to drill props down. We use it
 * to connect to the graphql server, send authorization headers automatically, and
 * make error messaging general instead of on every component (for debugging not
 * client-side).
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { 
  ApolloClient, 
  ApolloProvider,
  createHttpLink, 
  InMemoryCache, 
  from 
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { rootUrl } from './Constants'
// import reportWebVitals from './reportWebVitals';

import App from './App';

// Graphql server link
const httpLink = createHttpLink({
  uri: `${rootUrl}/graphql`
});

// Authorization header for graphql server
const authLink = setContext(async () => {
  const tokenVal = localStorage.getItem('instructorToken') ?? sessionStorage.getItem('token');
  return {
    headers: {
      Authorization: tokenVal ? `Bearer ${tokenVal}` : null
    }
  };
});
const fullLink = authLink.concat(httpLink); 

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Adding together the two separate links, I do not understand why we need the from array
// to be 2, parted instead of just listing all three links here. The order matters too. 
const additiveLink = from([
  errorLink,
  fullLink
]);
// eslint-disable-next-line no-console
console.log(additiveLink);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
