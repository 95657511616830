import { Parser } from 'json2csv';
import { DateTime } from 'luxon';

import { ResponseDAO } from '../../gql/schemas';



export default function CSVParser(queryStub: ResponseDAO[]) {
  /**
   * Parses a queryStub into a CSV file and downloads it.
   * 
   * @remarks
   * Takes a queryStub (either an array of ResponseDAOs or undefined) and if
   * the queryStub is not undefined, passes it to json2csv's parser to format
   * it into a CSV string. If the queryStub is undefined, the csv string will
   * be an empty one. It also formats certain fields in the following ways:
   * - The Assessment field is replaced with "Incomplete" if it was empty.
   * - The Score field is replaced with "None" if it was empty.
   * - The Created field is formatted via luxon's DateTime toLocaleString
   * - The Completed field is formatted via luxon's DateTime toLocaleString OR
   * replaced with "Incomplete" if it was empty.
   * 
   * Then, it creates a Blob object (and types it as text/csv;charset=utf-8),
   * creates a URL from that and returns it.
   * 
   * Note that this loads the entire json and processes it in-memory, which
   * currently is already true but may be a thing we try to make async at some
   * future point for performance's sake.
   * 
   * @param queryStub the query from InstructorMain.tsx, which will either be
   * an array of ResponseDAOs or undefined.
   * 
   * @returns an object url string
   */

  let csv = '';
  const opts = {
    // Grab only the fields we want
    fields: [
      {
        label: 'Student ID',
        value: 'subject.id'
      },
      {
        label: 'Last Name',
        value: 'subject.lastName',
      },
      {
        label: 'First Name',
        value: 'subject.firstName',
      },
      {
        label: 'Email',
        value: 'subject.email'
      },
      {
        label: 'Languages',
        value: 'language',
      },
      {
        label: 'Assessment',
        //  if the string is empty, replace with "Incomplete"
        value: (record: ResponseDAO) => record.assessment ?? "Incomplete",
      },
      {
        label: 'Score',
        // if no score, replace with "None"
        value: (record: ResponseDAO) => record.score ? record.score.toString() : "None",
      },
      {
        label: 'Created',
        // format timestamp
        value: (record: ResponseDAO) => DateTime.fromJSDate(
          new Date(record.tsStarted)).toLocaleString(DateTime.DATETIME_MED
          )
      },
      {
        label: 'Completed',
        // format timestamp, but first if it's not there,
        // replace with "Incomplete"
        value: (record: ResponseDAO) => {
          if (!record.tsCompleted) {
            return 'Incomplete'
          };
          return DateTime.fromJSDate(
            new Date(record.tsCompleted)
          ).toLocaleString(DateTime.DATETIME_MED)
        },
        // This shouldn't come up but just in case
        default: 'Incomplete'
      },
    ],
  };
  // now hand the Parser the pile of fields (or empty string if no queryStub)
  const parser = new Parser(opts);
  csv = parser.parse(queryStub);
  // Now convert that into a blob
  const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(csvBlob);
  // create anchor element and click it 
  const exporting = document.createElement('a');
  exporting.href = url;
  exporting.setAttribute('download', `student_search _results_${DateTime.now().toISODate()}.csv`);
  exporting.click();
};