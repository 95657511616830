/* 
 * This view is displayed when a subject has a complete exam. It should display
 * any necessary information for the student, based on client-chosen options queried
 * from the DB.
 */

import React from 'react';
import { useQuery } from '@apollo/client';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


import Hero from '../components/layouts/Hero'

import { SUBJECT_COMPLETED_RESPONSE_QUERY } from '../gql/queries';
import { useMyContext } from "../Context";



export default function Finished() {
  // credentials
  const tokenVal = sessionStorage.getItem('token');
  const { setLoggedStatus } = useMyContext();
  const { sid } = JSON.parse(tokenVal!.split(".")[0]);
  // query for exam exit page
  const { data, error, loading } = useQuery(
    SUBJECT_COMPLETED_RESPONSE_QUERY,
    {
      variables:
      {
        "token": tokenVal,
      }
    }
  );

  if (loading) {
    return <>
      <Typography>Completing processing...</Typography><CircularProgress />
    </>
  };
  if (error) {
    return <Typography>We are sorry, there has been an error.</Typography>
  };

  const { message, level, tsStarted, tsCompleted } = data.subject.completed;
  setLoggedStatus(false)
  return (
    <>
      <Hero>
        <TextField
          variant="filled"
          disabled
          label="Student Name"
          defaultValue={sid}
        />
        <Typography variant="h5">Exam Complete</Typography>
        <Typography variant="h6">{message}</Typography>
        <Typography variant="h6">Your level (if applicable): {level}</Typography>
        <Typography variant="h6">Time started: {tsStarted}</Typography>
        <Typography variant="h6">Time completed: {tsCompleted}</Typography>
        <Button
          variant="contained"
          href="/"
          color="primary"
        >
          Return To Main Page
        </Button>
        <Divider />
      </Hero>
    </>
  );
};