import React, { ReactElement } from 'react';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { ResponseSectionSchema } from "gql/schemas";
import ResponseViewItemParser from './ResponseViewItemParser';
import { QuestionSchema } from './ResponseQuestionsArrayConstructor';

type ResponseViewSectionParserProps = {
  classes: ClassNameMap,
  sections: ResponseSectionSchema[],
  questions: QuestionSchema[],
  tsStarted: Date,
};

export default function ResponseViewSectionParser(
  {
    classes,
    sections,
    questions,
    tsStarted
  }: ResponseViewSectionParserProps,
): Array<ReactElement> {
  /**
   * Parses the sections of a given response into an array of React elements
   * corresponding to the ResponseDAO's sections.
   * 
   * @remarks
   * Maps each section into a Grid item with a Card with headings for the id,
   * score, and description of the section, then a list of the items in the
   * section (produced by feeding the ResponseSection's items field to
   * ResponseViewItemParser).
   * This is still in progress, expect major changes.
   * 
   * 
   * @param classes - the ClassNameMap used to pass down CSS styling.
   * @param sections - the array of ResponseSectionSchema objects to parse
   * @param questions - the array of QuestionSchema objects to parse
   * @param tsStarted - Date object used to calculate time it took to complete
   * initial section.
   * 
   * @returns An array of React elements corresponding to the sections in the
   * response that was passed to it.
   */
  return sections.map(
    ({ ts, sectionId, pnts, section: { des } }: ResponseSectionSchema, index, array) =>
      <>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h4">Section ID: {sectionId}</Typography>
              <Typography variant="h5">Score {pnts}</Typography>
              <Typography variant="h5">
                Time to complete: {
                  /* Generate current section timestamp then subtract the
                   * previous array index timestamp, or if this is index 0, use
                   * the time the exam was started. Displays, rounding
                   * milliseconds since they're an undesirable level of
                   * accuracy.
                   */
                  humanizeDuration(
                    DateTime.fromJSDate(new Date(ts)).diff(
                      DateTime.fromJSDate(
                        new Date(array[(index - 1)]?.ts ?? tsStarted)
                      )
                    ).toMillis(),
                    { round: true } // rounds milliseconds
                  )
                }
              </Typography>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: des }} />
              <ul>
                {ResponseViewItemParser(classes, sectionId, questions)}
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </>
  );
};