/*
 * The subject matter expert re: testing has requested that submitting answers
 * to the questions in the classification section only be an option after a
 * short delay and therefore this custom NavButton will render either a 
 * CircularProgressButton or a Button depending on the "ready" boolean fed to
 * it, which will be on a timer in the classification view.
 */
import React from 'react';
import Button from '@material-ui/core/Button';

// Navigation button with loading logic built in
// but first we must pay the Typescript toll
type ButtonProps = {
  ready: boolean,
  text: string,
  color: 'inherit' | 'default' | 'primary' | 'secondary' | undefined,
  effect?: () => void,
  type?: 'button' | 'submit' | 'reset',
};

// now the actual button
export default function NavButton(
  {
    ready,
    text,
    color,
    effect = () => '',
    type = 'button'
  }: ButtonProps) {
  const button = <>
    <Button
      variant="contained"
      color={color}
      onClick={effect}
      type={type}
      disabled={!ready}
    >
      {text}
    </Button>
  </>;
  return (button);
};