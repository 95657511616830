// Basic button to render "% loaded" that works with typescript
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';



export default function CircularProgressButton(progress: number) {
  return (
    <>
      <Box position="relative" display="inline-flex">
        <CircularProgress />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" color="textSecondary">
            {`${progress}%`}
          </Typography>
        </Box>
      </Box>
    </>
  )
};