/*
 * This is our classification page, which will retrieve the questions needed to classify students by 
 * experience with Spanish in early life, adolescence, etc (per design, "heritage").
 * 
 */
import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import CircularProgressButton from '../components/core/CircularProgressButton';
import DisclaimerCard from '../components/classification/DisclaimerCard';
import { HeritageResponse, HeritageQuestionCard } from '../components/classification/HeritageQuestionCard';
import WritingPromptCard from '../components/classification/WritingPromptCard';
import SubmitCard from '../components/classification/SubmitCard';
import { CLASSIFICATION_QUERY } from '../gql/queries';
import { SUBJECT_CLASSIFICATION_MUTATION, SUBJECT_CREATE_RESPONSE_MUTATION } from '../gql/mutations';
import { useMyContext } from "../Context";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 300,
    },
    keyboard: {
      display: 'flex',
    }
  }),
);

export default function Classification(): JSX.Element {
  /**
   * Classification view covering demographics questions
   * 
   * @remarks
   * The various questions are handled as cards stored as separate components
   * in src/components/classification . They'll be contained in an array, and
   * navigated using the index of that array. This function also has hooks to
   * track state to hold the input from those questions, and timer
   * functionality to induce a delay (per request of the testing subject matter
   * expert) between any card being shown to the subject and the subject being
   * able to click the response buttons.
   * 
   * @returns JSX element, a Container which will have the current
   * classification card component displayed
   */
  const classes = useStyles()
  const history = useHistory();
  const tokenVal = sessionStorage.getItem('token');
  // keyboard props
  const { specialChars, setSpecialChars } = useMyContext()
  const keyboard = useRef(null);
  const [layoutName, setLayoutName]: [string, Function] = useState("default");

  // State hooks for navigating through the questions
  const [progress, setProgress] = useState(0); // Timer progress
  const [buttonReady, setButtonReady] = useState(false)  // button usable?
  const [position, setPosition] = useState(0)  // position in card array index

  // State hooks for holding input
  const [reasonInput, setReasonInput] = useState('')
  const [previousLevelInput, setPreviousLevelInput] = useState('')
  const [writingSampleInput, setWritingSampleInput] = useState('')
  const [
    heritageResponses, setHeritageResponses
  ] = useState<HeritageResponse[]>([]);
  // GraphQL query and mutation
  const { loading, error, data } = useQuery(CLASSIFICATION_QUERY, {
    variables: {
      "token": tokenVal,
    }
  });

  const [subjectClassificationMutation, classificationResult] = useMutation(
    SUBJECT_CLASSIFICATION_MUTATION, {
    variables: {
      "token": tokenVal,
    }
  });
  const [createResponse, responseResult] = useMutation(
    SUBJECT_CREATE_RESPONSE_MUTATION,
    {
      variables: {
        "token": tokenVal,
      }
    }
  );


  /* 
   * Timer to make sure, per subject matter expert request, the user cannot immediately click
   * answers and must read the questions.
   */
  // TODO: It is currently set to update VERY rapidly. Update before putting in prod
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 100); //

    return () => {
      clearInterval(timer);
      if (progress >= 100) { setButtonReady(true) }
    };
  });
  /* These useEffect hooks check after every render if the classification mutation
   * has returned, and then fire the createResponse mutation, then once the
   * createResponse mutation has returned with data, use that to update the tokens
   * and navigate to the exam proper without further input from the user. 
   * It's janky, but it works.
   */
  useEffect(() => {
    if (classificationResult.data) {
      createResponse()
    }
  }, [classificationResult.data, createResponse])

  // Acquire special characters for special keyboard and send them to context
  useEffect(() => {
    if (data?.subject?.clientSettings) {
      const { upper, lower } = data.subject.clientSettings.specialChars
      setSpecialChars([lower.join(' ') as string, upper.join(' ') as string])
    }
  }, [data?.subject?.clientSettings?.specialChars]);

  // push them to the exam once it's ready
  useEffect(() => {
    if (responseResult.data) {
      if (responseResult.data.subject.createResponse) {
        sessionStorage.setItem(
          'token', responseResult.data.subject.createResponse.subjectToken
        )
        sessionStorage.setItem(
          'responseId', responseResult.data.subject.createResponse.responseId
        )
        history.push('/exam')
      }
    }
  }, [responseResult, classificationResult, createResponse])

  // Scroll to top upon changing cards
  useEffect(() => {
    document.getElementById("spacer")?.scrollIntoView()
  }, [position]);

  // Query issue handling (not robust)
  // TODO: Improve the messaging here after looking up industry / UX standards.
  if (loading) return (
    <>
      <Typography>
        Loading Questions...
      </Typography>{CircularProgressButton(progress)}</>
  );
  if (error) return (
    <>
      <Typography variant="h4">Something Has Gone Wrong.</Typography>
      <Typography>We are sorry, an error has occured.</Typography>
    </>
  );
  // These constants contain the query data itself
  const {
    disclaimer, writingPrompt, heritageQuestions
  } = data.subject.clientSettings
  const {
    reasonOptions, previousLevelOptions
  } = data.subject.clientSettings.demographicOptions

  /*
   * This array contains each card in order to navigate between them in order.
   * The disclaimer comes first, then the initial writing prompt question (which
   * also asks for the subject's previous level of classes in Spanish and reason
   * for taking it), then the questions re: the subject's heritage language 
   * experiences, then a final card to both allow for review and submission.
   */
  const index = [
    DisclaimerCard(
      {
        classes,
        disclaimer,
        buttonReady,
        setButtonReady,
        setProgress,
        position,
        setPosition,
      }
    ),
    WritingPromptCard(
      {
        classes,
        writingPrompt,
        writingSampleInput,
        setWritingSampleInput,
        reasonOptions,
        reasonInput,
        setReasonInput,
        previousLevelOptions,
        previousLevelInput,
        setPreviousLevelInput,
        buttonReady,
        setButtonReady,
        setProgress,
        position,
        setPosition,
        specialChars,
        keyboard,
        layoutName,
        setLayoutName
      }),];
  // generate the heritage question cards and add them to the array in order
  for (let i = 0; i < heritageQuestions.length; i += 1) {
    index.push(
      HeritageQuestionCard({
        heritagePosition: i,
        classes,
        heritageQuestions,
        heritageResponses,
        buttonReady,
        setButtonReady,
        setProgress,
        position,
        setPosition,
      }
      )
    )
  };
  // add the final card to let student see their answers and submit mutation
  index.push(
    SubmitCard({
      classes,
      writingSampleInput,
      setWritingSampleInput,
      reasonOptions,
      reasonInput,
      setReasonInput,
      previousLevelOptions,
      previousLevelInput,
      setPreviousLevelInput,
      heritageResponses,
      setHeritageResponses,
      buttonReady,
      setButtonReady,
      setProgress,
      position,
      setPosition,
      subjectClassificationMutation,
      tokenVal,
    }
    )
  )
  // actually rendering the page
  return (
    <>
      <Container maxWidth="sm">
        {index[position]}
      </Container>
    </>
  );
};

