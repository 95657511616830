/*
 * This view displays the student sign in page with the header.  The sign in
 * component is a page and not a modal because it makes things not
 * only a bit easier on the login logic but it makes it clear that
 * to proceed they must log in and there will be a new website for
 * them to use and they will not be retuned to the landingn page (or
 * whatever page served them the modal) after login.
 */
import React from 'react';

import SignInSubject from '../../components/login/SignInSubject'

function Student() {
  return (
    <>
      <SignInSubject />
    </>
  );
};

export default Student;