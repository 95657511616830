/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

function round(value: number) {
  return Math.round(value * 1e4) / 1e4;
};

const styles = (theme: Theme) => createStyles({
  margin: {
    margin: theme.spacing(7)
  },
  marginBottom: {
    marginBottom: theme.spacing(12)
  },
  widthSmall: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(660 + theme.spacing(6))]: {
      width: 660,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  widthMedium: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(850 + theme.spacing(6))]: {
      width: 850,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  widthLarge: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 880,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.up(round(880 / 0.7777))]: {
      width: '77.7777%'
    },
    [theme.breakpoints.up(round(1400 / 0.7777))]: {
      width: 1400
    }
  },
  widthXlarge: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.up(round(900 / 0.9))]: {
      width: '90%'
    },
    [theme.breakpoints.up(round(1800 / 0.9))]: {
      width: 1800
    }
  },
  widthFull: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  }
});

interface Props {
  children?: any;
  className?: string;
  component?: any;
  fullHeight?: boolean;
  fullWidth?: boolean;
  margin?: number;
  marginBottom?: number;
  style?: object;
  width?: string;
  other?: object;
  classes?: any;
};

function Body(props: Props) {
  const {
    children,
    classes,
    className,
    fullHeight,
    fullWidth,
    margin,
    marginBottom,
    style,
    ...other // This kind of prop spreading, even for styles is discouraged, but for the moment I am ignoring it in the ESlinter for now
  } = props;

  let { width, component: Component } = props;

  if (!width) {
    width = "medium";
  }
  if (!Component) {
    Component = "div";
  }

  return (
    <Component
      className={classNames(
        {
          [classes[`width${capitalize(width)}`]]: !fullWidth,
          [classes.fullHeight]: fullHeight,
          [classes.margin]: margin,
          [classes.marginBottom]: marginBottom
        },
        className
      )}
      style={style}
      {...other}
    >
      {children}
    </Component>
  );
};

Body.defaultProps = {
  children: undefined,
  className: undefined,
  component: undefined,
  fullHeight: undefined,
  fullWidth: undefined,
  margin: undefined,
  marginBottom: undefined,
  style: undefined,
  width: undefined,
  other: undefined,
  classes: undefined
};

export default withStyles(styles)(Body);
