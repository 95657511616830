/*
 * The disclaimer card is the first question served by the classification view.
 * It doesn't require any information to be kept, since navigating past it 
 * signifies acceptance.
 * The component renders the html from the client settings disclaimer, and then
 * Back / Accept versions of the NavButton component.
 */
import React from 'react'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

type NavigationDisclaimerProps = {
  classes: any,
  setShownDisclaimer: Function,
};

export default function NavigationDisclaimer(
  {
    classes,
    setShownDisclaimer,
  }: NavigationDisclaimerProps
) {
  return (
    <Card className={classes.disclaimer}>
      <CardContent>
        <Typography variant="h4">
          Please read the following carefully before continuing.
        </Typography>
        <Typography>
          For each section of the exam, before pressing Submit check that the displayed responses reflect your intended choices.
        </Typography>
        <Typography>
          You will NOT be able to return to those questions after clicking submit.
        </Typography>
        <Divider className={classes.divider} />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShownDisclaimer(true)
          }}
        >
          I Understand
        </Button>
      </CardActions>
    </Card>
  )
};
