/*
 * Very basic app that serves two purposes: 
 * 1. The Context wrapper that allows for global variables
 * 2. Link in the Dashboard, which is the main wrapper that holds styles and routes
 * 
*/
import React, {useState} from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Dashboard from './components/layouts/Dashboard'
import { MyContext } from './Context'
import './App.css';
/*
 * There are two globals we want via Context, where a user is logged in (loggedIn) and
 * the user's name (user). There are two associated setters that use a useState to   
 *  
*/

// Sentry needs a custom History object, this creates it.
const history: History = createBrowserHistory();

/* 
 * Initialize Sentry tracking. Note that this MAY need to be moved to index.tsx if
 * we start seeing issues. It was originally in both places, I killed the other and
 * it still works so this seemed like the one to keep.
 */
Sentry.init({
  dsn: "https://2336424330df4f87a7b37715eeafc1c5@o38279.ingest.sentry.io/5915828",
  release: "ca9932bdb244",
  autoSessionTracking: true,
  environment: "dev",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'api-dev.vega-labs.com/', /^\//],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  /* TODO: Eventually lower this value, the following is from Sentry:
   * We recommend adjusting this value in production, or using tracesSampler
   * for finer control
   */
  tracesSampleRate: 1.0,
});

export default function App() {
  const [loggedIn, setLoggedStatus] = useState<boolean>(!!sessionStorage.getItem('token') || !!localStorage.getItem('instructorToken'))
  const [adminStatusLoggedIn, setAdminStatus] = useState<boolean>(!!localStorage.getItem('instructorToken'))
  const [user, setUser] = useState<string>('Please Sign In')
  // TODO: remove defaulting to spanish
  const [language, setLanguage] = useState<string>('spanish')
  const [specialChars, setSpecialChars] = useState<string[]>([''])
  return (
    <MyContext.Provider value={ {loggedIn, user, language, adminStatusLoggedIn, setLoggedStatus, setAdminStatus, setUser, setLanguage, specialChars, setSpecialChars}}>
      <Router history={history}>
        <Dashboard />
      </Router>
    </MyContext.Provider>
  )
};
