/* The Dashboard is our main wrapper, it will not render as the Routes inside
 * change while the subject moves through the examination. 
 * Any of the renders that do need to happen (such as username or loggedIn are
 * handled through the custom useMyContext hook/function. Everything that is
 * viewed is in <Routes />.
 * This is based heavily on the @material-ui dashboard, but many changes could be made stylistically. 
*/

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Link as MyRouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { instructorListItems } from './DashList';
import Routes from '../../routers/Routes'
import { useMyContext } from "../../Context";

// The footer for everything
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://vega-labs.com">
        https://vega-labs.com
      </Link>{' '}
      {DateTime.now().year}
      .
    </Typography>
  );
}

// Style defaults 
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  rightLink: {
    fontSize: 16,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  textTestStyle: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function Dashboard() {

  // State hooks and Context
  const classes = useStyles();
  const {
    loggedIn,
    user,
    adminStatusLoggedIn,
    setLoggedStatus,
    setUser,
    setAdminStatus
  } = useMyContext();
  const [open, setOpen] = useState(false);

  // Logic for opening and closing the left menu drawer
  const handleDrawerOpen = () => {
    setOpen(true);
    // console.log(jsonToken);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const SideBarButton = <>
    <IconButton
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawerOpen}
      className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
    >
      <MenuIcon />
    </IconButton>
  </>
    ;

  const Sidebar = <>
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {user}
          </Typography>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{instructorListItems}</List>
    </Drawer>
  </>
    ;

  useEffect(() => {
    document.getElementById("spacer")?.scrollIntoView()
  });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {adminStatusLoggedIn ? SideBarButton : <></>}
          <MyRouterLink
            to="/"
            color="inherit"
            className={classes.title}
          >
            <Typography variant="h6" color="inherit" className={classes.title}>
              Spanish Language Heritage Exam
            </Typography>
          </MyRouterLink>
          {
            loggedIn ?
              <IconButton color="secondary">
                <MyRouterLink
                  to="/"
                  onClick={() => {
                    sessionStorage.clear()
                    localStorage.clear()
                    setLoggedStatus(false)
                    setUser('Please Sign In')
                    setAdminStatus(false)
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.rightLink}
                    endIcon={<ExitToAppOutlinedIcon />}
                  >
                    Logout
                  </Button>
                </MyRouterLink>
              </IconButton> :

              <Typography
                variant="h6"
                color="inherit"
                className={classes.rightLink}
              >
                Welcome!
              </Typography>
          }

        </Toolbar>
      </AppBar>
      {adminStatusLoggedIn ? Sidebar : <></>}
      <main className={classes.content}>
        <div id="spacer" className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Routes />
        </Container>
        <Divider /> {/* We do need some spacing here */}
        <Box pt={4} marginTop="auto">
          <Copyright />
        </Box>
      </main>
    </div>
  );
}